export function validade(stage, cvalue) {
    if ((stage.required) && (!cvalue)) {
        return { value: cvalue, error: 'Campo obrigatório.' }
    }

    let result = false
    switch (stage.id) {
        case 'cep':
            result = validateRegex(cvalue, RegExp(/^[0-9]{5}-[0-9]{3}$/));
            break;
        case 'email':
            result = validateRegex(cvalue, RegExp(/[^@]+@[^@]+.[^@]+/));
            break;
        case 'celular':
            //result = validateRegex(cvalue, RegExp(/^[0-9]{2}-[0-9]{9}$/));
            /* VLRCONT-159 */
            result = validateRegex(cvalue, RegExp(/\([\d]{2}\) ([\d]{4,5})-([\d]{4})$/));
            break;
        case 'maioridade':
            if (cvalue === 'Não') {
                result = 'Necessário ter mais de 18 anos. Não se preocupe, este site segue a LGPD e nenhum dado pessoal de menores de 18 anos será armazenado.'
            }
            break;
        case 'nome':
            result = name(cvalue)
            if (!result) {
                if (validateRegex(cvalue, RegExp(/^[A-Za-z\s]+$/))) {
                    result = 'Acentos, cedilhas e números não são permitidos'
                }
            }  
            break;
        case 'sobrenome':
            result = name(cvalue)
            if (!result){
                if (validateRegex(cvalue, RegExp(/^[A-Za-z\s]+$/))) {
                    result = 'Acentos, cedilhas e números não são permitidos'
                }
            }
            break;
        // Git: Validaçao do CPF e Especifique obrigatório
        case 'especifique_field': 
            result = especifiq(cvalue)
            break;        
        case 'cpf':
            if (cvalue) {
                result = maxLength(cvalue, stage.maxLength)
                if (!result) { result = validateRegex(cvalue, RegExp(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/)); }
                if (!result) { result = cpf(cvalue) }
            }
            break;
        case 'cnpj':
            if (cvalue) {
                result = maxLength(cvalue, stage.maxLength)
                if (!result) { result = cnpj(cvalue) }
            }
            break;
        case 'empresa':
            result = empresa(cvalue)
            if (!result) {
                if (validateRegex(cvalue, RegExp(/^[A-Za-z\s]+$/))) {
                    result = 'Acentos, cedilhas e números não são permitidos'
                }
            }
            break;
        case 'login': // VLRCONT-314
            if (cvalue) {
                result = maxLength(cvalue, stage.maxLength)
                if (!result) { result = login(cvalue); }  
            }
            break;
        case 'dtInicioNegociacao': 
            if (cvalue === 'Não') {
                let today = new Date().toLocaleDateString('pt-CA');
                result = `A data selecionada não pode ser inferior a ${today}.`
            }
            break;
        default:
            break;
    }

    if (typeof(result) === "undefined")
        result = false
    
    return { value: cvalue, error: result }
}

function validateRegex(cvalue, REGEX) {
    if (!REGEX.test(cvalue)) {
        return 'Formato inválido. Revise, por favor.';
    }
    return false;
}

function maxLength(cvalue, max) {
    if (cvalue) {
        if (max < cvalue.length) {
            return 'Limite de caracteres alcançado. ' + max
        }
    }
}

function name(cvalue) {
    if (cvalue) {
        if (cvalue.length < 3) {
            return 'Nome inválido. Revise, por favor.'
        }
    }
}

// Git: Validaçao do CPF e Especifique obrigatório
function especifiq(cvalue) {
    if(cvalue === '' || typeof(cvalue) === "undefined" || cvalue === ' ')
    {
        return 'Campo inválido. Revise, por favor.'
    }
    else
    {
        if (cvalue.length < 3) {
            return 'Campo inválido. Revise, por favor.'
        }
    }
}

function empresa(cvalue) {
    if (cvalue) {
        if (cvalue.length < 3) {
            return 'Nome da empresa inválido. Revise, por favor.'
        }
    }
}

function cpf(strCPF) {
    const TEXT_CPF_INVALID = 'CPF inválido. Revise, por favor.'
    strCPF = strCPF.replace(/[^0-9]/g, '')

    let Soma = 0;
    let Resto;

    if (strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999") return TEXT_CPF_INVALID;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return TEXT_CPF_INVALID;

    Soma = 0;
    
    for (let i=1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return TEXT_CPF_INVALID;

    return false;
}

function cnpj(strCNPJ) {
    const TEXT_CNPJ_INVALID = 'CNPJ inválido. Revise, por favor.'

    if (!strCNPJ) return TEXT_CNPJ_INVALID

    const validTypes =
        typeof strCNPJ === 'string' || Number.isInteger(strCNPJ) || Array.isArray(strCNPJ)

    if (!validTypes) return TEXT_CNPJ_INVALID

    const numbers = strCNPJ.toString().match(/\d/g).map(Number)

    if (numbers.length !== 14) return TEXT_CNPJ_INVALID

    const items = [...new Set(numbers)]
    if (items.length === 1) return TEXT_CNPJ_INVALID

    const calc = (x) => {
        const slice = numbers.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
            const n = slice[x - i]
            sum += n * factor--
            if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
    }

    const digits = numbers.slice(12)

    const digit0 = calc(12)
    if (digit0 !== digits[0]) return TEXT_CNPJ_INVALID

    const digit1 = calc(13)
    return !(digit1 === digits[1])
}

// VLRCONT-314
function login(cvalue) {
    if (cvalue) {
        if (cvalue.length < 3) {
            return 'Login. formato inválido, revise por favor.';
        }
        else {
            return validateRegex(cvalue, RegExp(/^[a-zA-Z0-9.@]+$/));
        }
    }
}