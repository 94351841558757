import React, { Fragment, useState } from 'react';
import { Paper, Box, makeStyles, Typography, Divider } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({

    btn: {
        backgroundColor: '#3D3078',
        color: '#fff',
        padding: '13px 26px',
        borderRadius: '4px',
        marginTop: '50px',
        width: '100%',
        "&:hover": {
            backgroundColor: '#01d1fe',
            color: '#fff',
        }
    },  
    descrPacotes: {
        textAlign: 'left',
        font: 'normal normal normal 23px/37px Roboto!important',
        letterSpacing: '0px',
        color: '#7B7B7B',
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            font: 'normal normal 19px/25px Roboto!important',            
            justifyContent: 'space-between',
            lineHeight: '30px!important'
        }
    },
    nomeCliente: {
        top: '1272px',
        //left: '198px',
        width: '971px',
        height: '48px',
        textAlign: 'center',
        font: 'normal normal normal 36px/48px Roboto!important',
        letterSpacing: '0px',
        color: '#01D1FE',
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            font: 'normal normal 24px/32px Roboto!important',
            width: '100%',
        }
    },
    descrCliente : {
        top: '1325px',
        //left: '198px',
        width: '971px',
        height: '26px',
        textAlign: 'center',
        //letterSpacing: 'NaNpx',
        color: '#7B7B7B',
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        }
    },
    quotes: {
        font: 'italic normal bold 60px/32px Times New Roman',
        letterSpacing: '0px',
        fill: '#01D1FE!important',
        opacity: '1',
    },
    boxFixedHeight: {
        height: '250px!important',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            height: '545px!important',
        }
    },
    carouselIndicatorsProps: {
        color: 'transparent',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #707070',
        margin: '15px!important',
        "&:hover": {        
            color: 'transparent',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            color: 'transparent',
        }
    },
    carouselActiveIndicatorsProps: {
        color: '#01D1FE',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #01D1FE',
        margin: '15px!important',
        background: '#01D1FE 0% 0% no-repeat padding-box',
        "&:focus": {
            background: '#01D1FE 0% 0% no-repeat padding-box',
            border: '1px solid #01D1FE',
            color: '#01D1FE',
        },
    },
    carouselNavButtonProps: {
        display: 'block',
        backgroundColor: '#fff!important',
        color: '#01D1FE!important', 
        borderRadius: '0!important',
        opacity: '1!important',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        }
    },
    setas: {
        height: '40px',
        width: '25px'
    },
}));


export default function CarouselLanding(props) {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
        setInputValue(event);
    };


    const content1 = (
        <Fragment>            
            <Box className={classes.boxFixedHeight}>
                <Typography className={classes.descrPacotes}>
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&ldquo;</text>
                    </svg>
                        Há dois anos, utilizo o Valor PRO. Estou satisfeito com sua eficácia. As atualizações em tempo real permitem decisões ágeis e precisas. As informações históricas confiáveis fornecem uma base sólida para análises. O Valor PRO é agora uma ferramenta crucial para nosso dia a dia, impulsionando nossa vantagem competitiva e garantindo resultados consistentes.
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&rdquo;</text>
                    </svg>
                </Typography>
            </Box>
            <Divider /><br/>
            <Box>
                <Typography className={classes.nomeCliente}>Victor Marangoni</Typography>
                <Typography className={classes.descrCliente}>Coordenador de Mesa de Operações & Middle Office</Typography>
            </Box>
        </Fragment>
    )

    const content2 = (
        <Fragment>
            <Box className={classes.boxFixedHeight}>
                <Typography className={classes.descrPacotes}>
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&ldquo;</text>
                    </svg>
                        Sou usuário do Valor PRO desde 2012, e gostaria de destacar a sua incrível capacidade de fornecer notícias em tempo real, cotações real time (mercado local e offshore) e uma interface extremamente amigável e de fácil manuseio.<br/>
                        A profundidade de entrega de informações e a facilidade de navegação fazem do Valor PRO uma ferramenta essencial para o meu trabalho. Não à toa uso há mais de 10 anos.
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&rdquo;</text>
                    </svg>
                </Typography>
            </Box>
            <Divider /><br/>
            <Box>                
                <Typography className={classes.nomeCliente}>Leandro Simidamore</Typography>
                <Typography className={classes.descrCliente}>Trader de Mesa de Ativos</Typography>
            </Box>
        </Fragment>
    )
    
    const content3 = (
        <Fragment>
            <Box className={classes.boxFixedHeight}>
                <Typography className={classes.descrPacotes}>
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&ldquo;</text>
                    </svg>
                        Utilizo o Valo PRO há cerca de três anos e posso afirmar que é uma ferramenta indispensável no meu trabalho diário. As informações de empresas, aliadas às notícias em tempo real e dados históricos, são imprescindíveis para nossas análises e decisões de investimento.
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&rdquo;</text>
                    </svg>
                </Typography>
            </Box>
            <Divider /><br/>
            <Box>                
                <Typography className={classes.nomeCliente}>Ana Costa</Typography>                
                <Typography className={classes.descrCliente}>Analista Financeira</Typography>
            </Box>
        </Fragment>
    )
    const content4 = (
        <Fragment>
            <Box className={classes.boxFixedHeight}>
                <Typography className={classes.descrPacotes}>
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&ldquo;</text>
                    </svg>
                        Sou usuário do Valo PRO desde 2015 e estou impressionado com a qualidade das informações e a facilidade de uso. As cotações em tempo real e notícias são essenciais para o meu dia a dia. A interface intuitiva torna todo o processo de navegação muito agradável.
                    <svg height="25px" width="40px">
                        <text y="45px" x="-5" className={classes.quotes}>&rdquo;</text>
                    </svg>
                </Typography>
            </Box>
            <Divider /><br/>
            <Box>                
                <Typography className={classes.nomeCliente}>Thiago Fernandes</Typography>
                <Typography className={classes.descrCliente}>Operador de Renda Variável - Broker</Typography>
            </Box>
        </Fragment>
    )

    var items = [
        { content: content1 },
        { content: content2 },
        { content: content3 },
        { content: content4 },
    ]

    return (
        <Carousel
            autoPlay="true"
            interval="20000"
            animation="slide"
            fullHeightHover="false"
            navButtonsAlwaysVisible="true"
            indicatorIconButtonProps={{ className: classes.carouselIndicatorsProps }}
            activeIndicatorIconButtonProps= {{ className: classes.carouselActiveIndicatorsProps }}
            navButtonsProps={{ className: classes.carouselNavButtonProps }} 
            PrevIcon={ <img src="./img/icons/angle-left-solid-blue.svg" alt="Prev" className={classes.setas}/> }
            NextIcon={ <img src="./img/icons/angle-right-solid-blue.svg" alt="Next" className={classes.setas}/> }
        >
            { items.map((item, i) => <ContentLanding key={i} item={item} />) }            
        </Carousel >        
    )
}

export function ContentLanding(props) {
    
    return (
        <Paper className="bgPaperHome">
            <Box className="containerDepoimentos">
               {props.item.content}
            </Box>
        </Paper>
    )
}
