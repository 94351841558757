export function Mask(version)
{
    switch (version.mask) {
        case 'cellphone':
            version = typingBlocker(version)
            version = cellphone(version)
            break;
        case 'cep':
            version = typingBlocker(version)
            version = cep(version)
            break;
        case 'cnpj':
            version = typingBlocker(version)
            version = cnpj(version)
            break;
        case 'cpf':
            version = typingBlocker(version)
            version = cpf(version)
            break;        
        default:
            break;
    }
    return version
}

/* VLRCONT-159, 276 */
function cellphone(version)
{
    let data = version.value.replace(/[^0-9]/g, '');
    //version.value.replace(/[^0-9- ()]/, '');
    
    if(data.length > 2)
    {
        if (data.length >= 3)
        {
            version.value = data.replace(/(\d{1,2})(\d)/, "($1) $2");
        }
        if (data.length >= 5)
        {
            version.value = data.replace(/(\d{2})(\d{3,4})/, "($1) $2-");
        }
        if (data.length > 10)
        {
            version.value = data.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        }
    }

    return version
}

/* VLRCONT-276 */
export function cep(version)
{
    let data = version.value.replace(/[^0-9]/g, '');

    if(data.length >= 5)
    {
        if(data.length === 5)
        {
            version.value = data.replace(/(\d{5})/, "$1-");            
        }
        if(data.length >= 6)
        {
            version.value = data.replace(/(\d{5})(\d)/, "$1-$2");
        }
    }

    return version
}

/* VLRCONT-276 */
function cnpj(version)
{
    let data = version.value.replace(/[^0-9]/g, '');
    
    if (data.length >= 2)
    {
        if (data.length > 2)
        {
            version.value = data.replace(/(\d{2})/, "$1.");
        }
        if (data.length > 5)
        {
            version.value = data.replace(/(\d{2,3})(\d{3})/, "$1.$2.");
        }
        if (data.length > 8)
        {
            version.value = data.replace(/(\d{2,3})(\d{3})(\d{3})/, "$1.$2.$3/");
        }
        if (data.length > 13)
        {
            version.value = data.replace(/(\d{2,3})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }
    }
    
    return version
}

/* VLRCONT-276 */
function cpf(version)
{
    let data = version.value.replace(/[^0-9]/g, '');
    //version.value.replace(/[^0-9- ()]/, '');

    if (data.length >= 3)
    {
        if(data.length > 3)
        {
            version.value = data.replace(/(\d{3})/, "$1.");
        }
        if(data.length > 6)
        {
            version.value = data.replace(/(\d{3})(\d{3})/, "$1.$2.");
        }
        if(data.length > 9)
        {   
            version.value = data.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, "$1.$2.$3-$4");
        }
    }

    return version
}

function typingBlocker(version)
{
    if (version !== undefined) {
        if (version.value.length > version.maxLength) {
            version.value = version.value.substring(0, version.maxLength)
            return version
        }
    }
    return version
}
