import React, { Fragment } from 'react';
import { Paper, Box, makeStyles, Typography, Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({

    btnSaibaMais: {
        backgroundColor: '#3D3078',
        color: '#fff',
        padding: '13px 26px',
        borderRadius: '4px',
        marginTop: '50px',
        width: '100%',
        "&:hover": {
            backgroundColor: '#01d1fe',
            color: '#fff',
        }
    },
    linePacotes: {
        margin: '13px 0 17px',
        BorderTop: '1px solid #c8c8c8',
    },
    descrPacotes: {
        top: '1543',
        left: '51px',
        width: '258px',
        height: '110px',        
        justifyContent: 'space-evenly',
        textAlign: 'left',
        font: 'normal normal 20px/28px Roboto',
        letterSpacing: '0px',
    },
    tituloValores: {
        textAlign: 'center',
        letterSpacing: '0px',
        //padding: '10px 0',
        color: '#7B7B7B',
        font: 'normal normal normal 18px/16px Roboto!important',
        opacity: '1',
    },
    btnAssinar: {
        width: '100%',
        height: '18px',
        background: '#01d1fe 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        font: 'normal normal normal 14px/16px Roboto!important',
        borderRadius: '4px',
        opacity: '1',        
        color: '#fff',
        "&:hover": {
            backgroundColor: '#01d1fe',
            color: '#fff',
        },
        padding: '19px 15px',
    },
    melhorOferta: {
        backgroundColor: '#48B05B',
        color: '#fff',
        font: 'normal normal 900 14px/16px Roboto!important',
        height: '20px',
        padding: '7px',        
        letterSpacing: '0.15px',
        borderRadius: '4px',
        textAlign: 'center',
        opacity: '1',
    },
    ofertaPadrao: {
        backgroundColor: '#F2F2F2',
        color: '#000',
        font: 'normal normal 900 14px/16px Roboto!important',
        height: '20px',
        padding: '7px',        
        letterSpacing: '0.15px',
        borderRadius: '4px',
        textAlign: 'center',
        opacity: '1',
    },
    boxValores: {
        height: '32px',
        textAlign: 'center',
        font: 'normal normal normal 14px/20px Roboto!important',
        letterSpacing: '0px',
        color: '#7B7B7B',
        opacity: '1',
    },
    smallAlert: {
        position: 'relative',
        top: '35px',
        right: '0px',
        font: 'normal normal normal 12px/16px Roboto!important',
        letterSpacing: '0.15px',
        maxWidth: '286px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            //right: '-230px',
            top: '25px',
            font: 'normal normal normal 12px/14px Roboto!important',
        },
    },
    precoAnual: {
        //position: 'relative',
        //top: '50px',
        right: '0px',
        font: 'normal normal normal 14px/18px Roboto!important',
        letterSpacing: '0.2px',
        maxWidth: '286px',
        color: '#7D7D7D',
        marginTop: '5px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    carouselIndicatorsProps: {
        color: 'transparent',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #707070',
        margin: '15px!important',
        "&:hover": {        
            color: 'transparent',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            color: 'transparent',
        }
    },
    carouselActiveIndicatorsProps: {
        color: '#01D1FE',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #01D1FE',
        margin: '15px!important',
        background: '#01D1FE 0% 0% no-repeat padding-box',
        "&:focus": {
            background: '#01D1FE 0% 0% no-repeat padding-box',
            border: '1px solid #01D1FE',
            color: '#01D1FE',
        },
    },
    carouselNavButtonProps: {
        display: 'block',
        backgroundColor: '#fff!important',
        color: '#01D1FE!important', 
        borderRadius: '0!important',
        opacity: '1!important',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        }
    },
    setas: {
        height: '40px',
        width: '25px'
    },
    tituloPlanos: {
        //color: '#000',
        font: 'normal normal 900 20x/16px Roboto!important',
        fontWeight: 'bold'
    }
}));

export default function CarouselPlanos(props) {
    const classes = useStyles();
    
    const [stage, setStage] = React.useState({
        loading: false,
        open: false,
        produto: {
            entrega: "", id_pacote: "3", id_produto: "", id_referencia: "", id_servico: "", desconto: "", 
            tipo_servico: "", descricao: "Valor PRO Bronze", mensal: "250,00", anual: "2.250,00", economia: "750,00"
        }
    });
    
    const contentMensal = (
        <Fragment>
            <center>
                <Box>
                    <Typography className={classes.tituloValores}>
                        {stage.produto.descricao}
                    </Typography>
                    <Typography className={classes.tituloPlanos}>Plano mensal</Typography>
                </Box>
                <br/>
                <Box className={classes.ofertaPadrao}>Oferta padrão</Box>
                <hr className={classes.linePacotes} />                                        
                <Box>
                    <Typography variant="body2" className={classes.boxValores}>
                        12x de R$ <strong>{stage.produto.mensal}</strong><br/>
                    </Typography>
                    <br/>
                    <Button className={classes.btnAssinar} href="#5ea73f46574f90a83a8212479800ffd1?3?1">Contratar</Button>
                </Box>
            </center>
        </Fragment>
    )
    
    const contentAnual = (
        <Fragment>
            <center>
                <Box>
                    <Typography className={classes.tituloValores}>
                        {stage.produto.descricao}
                    </Typography>
                    <Typography className={classes.tituloPlanos}>Plano anual</Typography>
                </Box>
                <br/>
                <Box className={classes.melhorOferta}>
                    <img src="./img/icons/coins-solid.svg" alt="moedas" width="15px"/>&nbsp;&nbsp;Melhor oferta
                </Box>
                <hr className={classes.linePacotes} />                                        
                <Box>
                    <Typography variant="body2" className={classes.boxValores}>
                        1x de R$ <strong>{stage.produto.anual}</strong> *<br/>
                        <Box className={classes.precoAnual}>Economize R$ {stage.produto.economia}</Box>
                    </Typography>
                    <br/>
                    <Button className={classes.btnAssinar} href="#5ea73f46574f90a83a8212479800ffd1?3?2">Contratar</Button>
                </Box>
                <Box className={classes.smallAlert}>* Cobrança anual</Box>
            </center>
        </Fragment>
    )

    var items = [
        { content: contentMensal, },
        { content: contentAnual, },
    ]

    return (
        <Carousel
        autoPlay="true"
        interval="20000"
        animation="slide"
        fullHeightHover="false"
        navButtonsAlwaysVisible="true"
        indicatorIconButtonProps={{ className: classes.carouselIndicatorsProps }}
        activeIndicatorIconButtonProps= {{ className: classes.carouselActiveIndicatorsProps }}
        navButtonsProps={{ className: classes.carouselNavButtonProps }} 
        PrevIcon={ <img src="./img/icons/angle-left-solid-blue.svg" alt="Prev" className={classes.setas}/> }
        NextIcon={ <img src="./img/icons/angle-right-solid-blue.svg" alt="Next" className={classes.setas}/> }
        >
            { items.map((item, i) => <ContentPlanos key={i} item={item} />) }
        </Carousel >
    )
}

export function ContentPlanos(props) {
    return (
        <Paper>
            <Box className="containerValoresModile">
               {props.item.content}
            </Box>
        </Paper>
    )
}