import React, { Fragment, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// fields
import { CircularDeterminate } from '../../components/Fields/loading.js'
import { ButtonSendRequest } from '../../components/Fields/button.js'
import { SimpleModal } from '../../components/Fields/modal.js'
import { Text } from '../../components/Fields/text.js'

// services
import { MAIL_API_EXCLUSAO } from '../../business/ExternalUrlService.js' /*, SV_API_EXCLUSAO*/
import { submit } from '../../business/ApiService.js' /*, submitSvApi*/
import { validateForm } from '../../business/UserService.js'
import { formConfig } from '../../business/ConfigService.js'
import { Mask } from '../../business/MaskService.js'

// libs
import  { Typography, Box, makeStyles } from '@material-ui/core'
import Config from '../../utils/config.js';

const { reCaptchaKey } = Config;

const useStyles = makeStyles(theme => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    fieldEspecifique: {
        display: 'none'
    },
    'reCaptcha-container': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem',
    }
}));

export function FormDelete(props) {
    
    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));
        
    const [stage, setStage] = React.useState({
        ...conf,
        titleModal: 'SOLICITAÇÃO ENVIADA!',
        textModal: 'Nosso time irá tratar a exclusão de seus dados conforme solicitado. Obrigado.',
        loading: false,
        open: false,        
        button: validateForm(conf)        
    });

    const [reCaptchaVerified, setReCaptchaVerified] = useState(false);

    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {
            version.titleModal = 'SOLICITAÇÃO ENVIADA!'
            version.textModal = error
            
            submit('POST', stage, MAIL_API_EXCLUSAO, version, callBack);
        }

        version.loading = false
        version.open = true
        setStage(version)
    };    
    
    const verifyCaptcha = (token) => {
        setReCaptchaVerified(true);
    };
    
    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
        version.loading = true;
        setStage(version)
        e.preventDefault();        

        submit('POST', stage, MAIL_API_EXCLUSAO, version, callBack);
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };
    
    const buttonLabel = () => {
        return (
            <Typography variant="body2">
                <span className={classes.middleTitle}>
                    <strong>Enviar Solicitação</strong>
                </span>
            </Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        variant: 'contained'
    };

    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };
    
    return (
        <form id={props.idForm} onSubmit={onFormSubmit} className="containerForm">
            <Box textAlign="left" className={classes.titleSpacing}>
                <Typography variant="subtitle1" className={classes.titleColor}>
                    <strong>Dados pessoais</strong>
                </Typography>
            </Box>
            <Box textAlign="left">
                <Text blur={handleBlur} change={handleChange} stage={stage.nome} />
            </Box>
            <Box textAlign="left">
                <Text blur={handleBlur} change={handleChange} stage={stage.sobrenome} />
            </Box>
            <Box textAlign="left">
                <Text blur={handleBlur} change={handleChange} stage={stage.cpf} />
            </Box>

            {
                props.idForm === 'exclusaoDeDados' ?
                <Fragment>
                    <Box textAlign="left">
                        <Text blur={handleBlur} change={handleChange} stage={stage.login} />
                    </Box>
                </Fragment> : <Fragment/>
            }

            <div className={classes['reCaptcha-container']}>
                <ReCAPTCHA sitekey={reCaptchaKey} onChange={verifyCaptcha} />
            </div>
            
            <ButtonSendRequest disabled={ stage.button || !reCaptchaVerified }>{button}</ButtonSendRequest>
            <CircularDeterminate loading={stage.loading} />
            <SimpleModal title={stage.titleModal} handleOpen={stage.open} handleClose={handleClose} text={stage.textModal}/>
        </form>
    )
}
