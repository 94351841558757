import React, { useState, useEffect } from 'react';

import api from '../../../business/Download.js'

// libs
import  {  Typography, Box, makeStyles, Button, Grid } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    middleTitle2: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        opacity: '1',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    btnAzulEscuro: {
        width: '234px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#FFF',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        //margin: '15px',
        width: '290px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    alertDefault: {
        backgroundColor: '#FFF',
        color: '#0D0048',
        font: 'normal normal medium 16px/19px Roboto',
        opacity: '1',
        letterSpacing: '0px',
        textAlign: 'left'
    },
    lineFormat: {
        border: '1px solid #00000026',
    },
    subtitleDownloads: {
        textAlign: 'left!important',
        font: 'normal normal normal 16px/28px Roboto!important',
        letterSpacing: '0px!important',
        color: '#303030!important',
    },
    subtitleDocs: {
        textAlign: 'center!important',
        font: 'normal normal medium 18px/28px Roboto!important',
        letterSpacing: '0px!important',
        color: '#333333!important',        
    }
}));

export function FormularioListarDownloads(props) {
    
    const classes = useStyles();
    //const conf = Object.assign({}, formConfig(props.idForm));

    /* USANDO AXIOS PARA GERAR O LINK DO ENDPOINT PYTHON */
    const [instalador, setInstaller] = useState();    
    useEffect(() => {
        api
          .get()
          .then((response) => setInstaller(response.data['message']))
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          });
    }, []);
    
    const onDownload = () => {
        const link = document.createElement("a");
            //link.download = file[file.length-1];
            link.href = instalador;
            link.click();
    }
    /* FIM AXIOS */
    
    return (
        <form id={ props.idForm } className="containerForm3" style={{ height: "406px" }}>
            <Box>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "30px" }}>
                    <Grid item sm>
                        <Typography className={ classes.subtitleDownloads }>Para acessar o Valor PRO é necessário possuir login, senha e atender os requisitos técnicos.</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "30px" }}>
                    <Grid item>
                        <Button className={ classes.btnAzulEscuro } onClick={ onDownload } rel="noopener noreferrer">Baixar o Valor PRO</Button>
                    </Grid>
                </Grid>                
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom: "15px" }}>
                    <Grid item>
                        <Typography className={ classes.subtitleDocs }>Documentação</Typography>
                    </Grid>
                </Grid>                
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom: "15px" }}>
                    <Grid item>
                        <Button href="../pdf/termos_de_uso_valorpro.pdf" target="_new" variant="text" size="medium" className={ classes.btnBranco }>Termo de uso Valor PRO</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom: "15px" }}>
                    <Grid item>
                        <Button href="../pdf/manual_addin_excel.pdf" target="_new" variant="text" size="medium" className={ classes.btnBranco }>Manual do Add-in (Excel)</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom: "15px" }}>
                    <Grid item>
                        <Button href="../pdf/requisitos_tecnicos.pdf" target="_new" size="medium" className={ classes.btnBranco }>Requisitos técnicos</Button>
                    </Grid>
                    <Grid item>
                        <Button href="../pdf/technical_requirements.pdf" target="_new" size="medium" className={ classes.btnBranco }>Technical requirements</Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}
