import React from 'react';

import { Box, makeStyles } from '@material-ui/core'
import { FormularioRecuperarSenha } from './form'

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: '#07002633',
        color: '#fff',
        paddingTop: '80px',
        paddingBottom: '80px',
        margin: '0px auto 0!important',
        height: '100vh',
    },
    container: {
        padding: '55px 0',
        //height: '318px',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 24px)',
            margin: 'auto',
            padding: '20px 0',
        },
        ['@media (max-width:320px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        },
    },
    textBox: {
        textAlign: 'center',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 50px)',
            margin: 'auto',
        },
    },
    blcHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '1196px',
        height: '80px',
        margin: 'auto',
        padding: '0',
        color: '#fff',
        textTransform: 'uppercase',
        ['@media (max-width:1230px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 70px)',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            justifyContent: 'space-around',
            padding: '0',
        },
        ['@media (max-width:880px)']: { // eslint-disable-line no-useless-computed-key
            height: '48px',
            justifyContent: 'center',
        },
    },
}));

export function RecuperarSenha() {

    if (!sessionStorage.getItem('username'))
    {
        sessionStorage.clear();
        window.location.replace('#/minhaConta');
    }

    const classes = useStyles();

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 200);

    return (
        <header style={{ backgroundColor: "#07002633" }}>
            <Box id="section" className={ classes.root }>
                <Box textAlign="center" className={ classes.container }>
                    <Box className="container formPadding">
                        <FormularioRecuperarSenha idForm="recuperarSenha" />
                    </Box>
                </Box>
            </Box>
        </header>
    )
}