import React, { Fragment, useState, useEffect } from 'react';

import { 
  Box, Button, Dialog, TextField, Radio, RadioGroup, InputLabel, FormControl, Typography, NativeSelect, Snackbar,
  FormControlLabel , DialogActions, DialogContent, DialogContentText, InputBase, withStyles, makeStyles
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

//import { validateForm } from '../../business/UserService'
import { formConfig } from '../../business/ConfigService'
import { AxiosSubmitScheduled } from '../../business/ApiService'
import { SV_API_CONTA } from '../../business/ExternalUrlService'


import 'date-fns';
import Axios from 'axios';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        //fontSize: '16px',
        //fontWeight: '600',
        //lineHeight: '1.4',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '1.28px!important',
        padding: '10px 25px 10px 25px'
    },
    paper: {
        position: 'absolute', 
        backgroundColor: '#fff',
        //boxShadow: '0px 10px 35px -10px rgba(112,112,112,1)',
        //backgroundColor: theme.palette.background.paper,
        //border: '1px solid #707070',
        //borderRadius: '4px',
        outline: 'transparent',        
        padding: theme.spacing(2, 4, 3),
    },
    label: {
        backgroundColor: '#ffffff',
        padding: '0 5px 0 5px',
    },
    modalTitle : {
      textAlign: 'left',
      font: 'normal normal bold 20px/26px Roboto',
      letterSpacing: '0px',
      color: '#0D0048',
      paddingBottom: '8px',
    },    
    btnAzulEscuro: {        
        //width: '114px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',        
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    icon: {
      left: '166px',
      //marginBottom: '16px',
      borderRadius: '50%',
      borderColor: '#7D7D7D',
      width: 20,
      height: 20,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#fff',
      //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#fff',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#fff',
      //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage: 'radial-gradient(#3D3078, #3D3078 38%,transparent 46%)',
        content: '""',        
      },
      'input:hover ~ &': {
        backgroundColor: '#fff',
      },
    },
    checkLabel: {
      height: '32px', 
      color: '#303030', 
      letterSpacing: '0.49px', 
      font: 'normal normal normal 16px/21px Roboto'
    },
    radioDescription : {
      textAlign: 'left', 
      font: 'normal normal normal 16px/28px Roboto', 
      letterSpacing: '0px', 
      color: '#303030', 
      marginLeft: '0px!important', 
      marginTop: '15px!important'
    },
}));

function StyledRadio(props) {
  const classes = useStyles();
  
  return (
    <Radio  
      checkedIcon={ <span className={clsx(classes.icon, classes.checkedIcon)}/> } 
      icon={ <span className={classes.icon} /> } 
      {...props}
    />
  );
}


const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #3D3078', /*#ced4da*/
    fontSize: 16,
    textAlign: 'left',
    padding: '20px 26px 20px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto'
    ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#3D3078', //'#80bdff',
        //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
  },
}))(InputBase);


export function MyAccountClientDialog(props){
  const classes = useStyles();
  const conf = Object.assign({}, formConfig(props.id));

  //console.log(conf)
  
  /*SNACKBAR*/
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');

  /* CONTRUTORES DA MODAL */  
  const [scheduledBody, setScheduled] = useState(null);
  const [submitButton, setSubmitButton] = useState("");
  const [dialogContentTextSize, setDialogContentTextSize] = useState({});
  const [dialogContentSize, setDialogContentSize] = useState({});
  const clientValues = (typeof props.value !== "undefined") ? props.value.split(";") : "";
  
  /*PACOTES*/  
  const GetServices = () => {
    const [servicesBck, setServicesBck] = useState([]);
    useEffect(() => {
      async function fetchData(){
        var idUsuarioConta = parseInt(sessionStorage.getItem('idUsuarioConta'));
        var instance = Axios.create({
            baseURL: SV_API_CONTA,
            timeout: 3000,
            headers: { 
              "Accept": "*/*",
              "Content-type": "application/json; charset=utf-8", 
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        });

        const r =  await instance.get(`/master/services?idUsuarioConta=${idUsuarioConta}`).then((response) => { return response.data.message }).catch((err) => { return err.response.data.message });
        setServicesBck(r);
      }

      fetchData();

    }, []);
    
    var servicesArray = [];
    for(let i=0; i < servicesBck.length; i++)
    {
      servicesArray.push({ key: servicesBck[i].idPackage, value: servicesBck[i].packageName });
    }

    /* caso contenha dados realiza a ordem alfabetica */
    if (servicesArray.length > 0)
    {
      const afterSorting = servicesArray.sort((first, second) => {
        if (first.value.toUpperCase() < second.value.toUpperCase()) return -1;
        if (first.value.toUpperCase() > second.value.toUpperCase()) return 1;
          return 0;
      });
      
      return afterSorting;
    }
    else
    {
      return [];
    }

  }

  /* chmando funcao fora para evitar loop render */
  const services = GetServices();  

  /* CRIA DATA D+1 PARA CONTROLE DO CAMPO DE AGENDAMENTO */
  const initialDate = () => {
    let d = new Date();
    let time = (props.title === "ActiveModal") ? 86400000 : 0;    
    return (new Date(d.getTime() + parseInt(time)).toLocaleDateString('en-CA'));
  }

  /* CRIA UM TEXTFIELD COM DATA PARA USO DENTRO DA MODAL */
  const CssOutlinedField = ({formatStyle, values, labels, description }) => {
    const CssTextField = (formatStyle === 'default') ? 
      withStyles({root: {} })(TextField)
      :
      withStyles({
        root: {
          '& label': { color: '#B00020!important', },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: '#B00020!important' },
            '&:hover fieldset': { borderColor: '#B00020!important' },
            '&.Mui-focused fieldset': { borderColor: '#B00020!important' },
          },
        },
      })(TextField);

    return (
      <Box className={ classes.boxOption }>          
        <CssTextField
          variant="outlined" 
          label={ labels }
          type="date" 
          defaultValue={ values }
          InputLabelProps={{ shrink: true, }}
          onChange={ dateLimitForce }
          style={{ width: "100%" }} 
          change={ handleChangeModal } 
          id="agendamento"
        />
        <span className={ classes.radioDescription }><p>{ description }</p></span>
      </Box>
    );
  }

  /* CHAMADA PADRAO DO INPUT INICIAL DE TODA MODAL */
  useEffect(() => {
    if(props.title === "ActiveModal" && typeof props.text !== "undefined")
    {
      var style1 = { width: '540px', height: '280px' };
      var style2 = { width: '524px', height: '280px', left: '0px', top: '0px' };
      setDialogContentSize(style1);
      setDialogContentTextSize(style2);
      setSubmitButton('Ativar usuário');

      setScheduled(
        <span className={ classes.radioDescription }>
          <p>O usuário ganhará acesso à ferramenta imediatamente.</p>
        </span>
      );
    }
    else if(props.title === "InactiveModal" && typeof props.text !== "undefined")
    {
      var style1 = { width: '540px', height: '190px' };
      var style2 = { width: '524px', height: '190px', left: '0px', top: '0px' };
      setDialogContentSize(style1);
      setDialogContentTextSize(style2);
      setSubmitButton('Agendar desativação');

      setScheduled(
        <CssOutlinedField 
          formatStyle={ 'default' } 
          values={ initialDate() } 
          labels={ 'Agendar desativação para' } 
          description={ 'O usuário perderá acesso à ferramenta ao final do dia.' }
        />
      );
    }

  }, [props]);

  /* REGRA PARA FORCAR A DATA MININA D+1 NO AGENDAMENTO */
  const dateLimitForce = async (e) => {
    let userDate = e.target.value;
    let labelField = (props.title === "ActiveModal") ? 'Agendar ativação para' : 'Agendar desativação para' ;
    let descriptionField = (props.title === "ActiveModal") ? 
      'O usuário ganhará acesso à ferramenta ao início do dia.' : 'O usuário perderá acesso à ferramenta ao final do dia.';

    if((userDate.replace(/-/g, "")) < (initialDate().replace(/-/g, "")))
    {
      let limitDtLabel = initialDate().split("-");
          limitDtLabel = `${ limitDtLabel[2] }/${ limitDtLabel[1] }/${ limitDtLabel[0] }`;

      setScheduled(
        <CssOutlinedField 
          formatStyle={ 'default' } 
          values={ initialDate() } 
          labels={ `A data selecionada não pode ser inferior a ${limitDtLabel}` } 
          description={ descriptionField }
        />
      );
    }
    else
    {
      setScheduled(
        <CssOutlinedField 
          formatStyle={ 'default' } 
          values={ userDate } 
          labels={ labelField } 
          description={ descriptionField }
        />
      );
    }
  }

  /* EXIBE OPCOES NOVAS PARA ATIVACAO */ 
  const radioSelected = async (e) => {
    if(e.target.value === "0")
    {
      var style1 = { width: '540px', height: '280px' };
      var style2 = { width: '524px', height: '280px', left: '0px', top: '0px' };
      setDialogContentSize(style1);
      setDialogContentTextSize(style2);
      setSubmitButton('Ativar usuário');

      setScheduled(
        <span className={ classes.radioDescription }>
          <p>O usuário ganhará acesso à ferramenta imediatamente.</p>
        </span>
      );
    }
    else
    {
      var style1 = { width: '540px', height: '340px' };
      var style2 = { width: '524px', height: '340px', left: '0px', top: '0px' };
      setDialogContentSize(style1);
      setDialogContentTextSize(style2);
      setSubmitButton('Agendar ativação');

      setScheduled(
        <CssOutlinedField 
          formatStyle={ 'default' } 
          values={ initialDate() } 
          labels={ 'Agendar ativação para' } 
          description={ 'O usuário ganhará acesso à ferramenta ao início do dia.' }
        />
      );
    }
  }

  /* CONFIGURACAO DO BOTAO SUBMIT */
  const buttonLabel = () => {
    return (
      <Typography variant="body2">
        <span className={ classes.middleTitle }>{ submitButton }</span>
      </Typography>
    )
  };

  // const button = {
  //   title: buttonLabel(),
  //   class: 'button',
  //   classes: classes.btnAzulEscuro
  // };

  /* CONTROLES DE FORM */
  const [stage, setStage] = useState({
    ...conf,
    textSnack: "Agendamento realizado com sucesso.",
    //button: validateForm(conf)
  });

  const handleChangeModal = (field) => {
    let version = Object.assign({}, stage);
    version[field.id] = field;
    setStage(version);
  };

  const handleBlurModal = (field) => {
    let version = Object.assign({}, stage);
    version[field.id] = field;
    setStage(version);
  };
    
  const handleCloseModal = () => { return false }
  const handleClose = () => { return false }

  const callBack = (error) => {
    let version = Object.assign({}, stage)
        
    if (error) {        
        version.textSnack = error;
        setAlertSeverity("error"); /* SNACKBAR */
    }

    setStage(version);
    setAlertMessage(version.textSnack);
    setOpen(true);
  };

  const onFormSubmit = async (e) => {
    
    let version = Object.assign({}, stage);
        version.loading = true;
        setStage(version)
      e.preventDefault();

    let descricaoModal = document.getElementById('descricao_modal');
    let agendamento = document.getElementById('agendamento');
    let date = (new Date().toLocaleDateString('en-CA'));

    if (props.title === 'ActiveModal')
    {

      let dadosReativa = {
        "idServicoSelecionado": (descricaoModal === null) ? parseInt(clientValues[0]) : parseInt(descricaoModal.value),
        "dtInicio": (agendamento === null) ? `${date}T00:00:00.000Z` : `${agendamento.value}T00:00:00.000Z`,
        "dtFim": clientValues[2],
        "idUsuarioContaMaster": parseInt(sessionStorage.getItem('idUsuarioConta')),
        "login": (props.text).toString()
      }

      AxiosSubmitScheduled('/venda/reativa/master/pessoa/fisica', dadosReativa, version, callBack);
    }
    else
    {
      let dadosCancela = {
        "idUsuarioContaMaster": parseInt(sessionStorage.getItem('idUsuarioConta')),
        "dtFim": (agendamento === null) ? `${date}T23:59:59.999Z` : `${agendamento.value}T23:59:59.999Z`,
        "idServico": parseInt(clientValues[0]),
        "login": (props.text).toString()
      }

      AxiosSubmitScheduled('/cancelamento/master/pessoa/fisica', dadosCancela, version, callBack);
    }

  }

  /* MODAL */
  return (
    <>
      <Box id={ props.id }>
        <Dialog 
          open={(props.handleOpen) ? props.handleOpen : false }
          onClose={(props.handleClose) ? props.handleClose : handleClose }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={ dialogContentSize }>
            <DialogContentText style={ dialogContentTextSize } className={ clsx(classes.paper, 'dialog') } onClose={ handleCloseModal }>
              <h2 className={ classes.modalTitle }>
                { props.title === 'ActiveModal' ? 'Ativar este usuário?' : 'Desativar este usuário?' }
              </h2>
              <Box display="row" justifyContent="left">
                { props.title === 'ActiveModal' ? 
                  (
                    <Box>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="descricao-label" className={ classes.label }>Plano *</InputLabel>
                        <NativeSelect labelId="descricao-label" id="descricao_modal" onChange={ handleChangeModal } input={ <BootstrapInput /> } >
                          { 
                            services.map( ({key, value}) => { 
                              if (key === clientValues[0])
                                return <option value={ key } selected>{ value }</option>
                              else
                                return <option value={ key }>{ value }</option>
                              } 
                            ) 
                          }
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  ) : ''
                }
                { props.title === 'ActiveModal' ?
                    (
                      <Box style={{ marginLeft: '9px' }}>
                        <br/>
                        <RadioGroup aria-label="agendar" defaultValue="0" onChange={ radioSelected } id="agendar" required={ true }>
                          <FormControlLabel value="0" control={ <StyledRadio /> } label="&nbsp;&nbsp;Imediatamente" className={ classes.checkLabel }/>
                          <FormControlLabel value="1" control={ <StyledRadio /> } label="&nbsp;&nbsp;Agendar" className={ classes.checkLabel }/>
                        </RadioGroup>
                      </Box>
                    ) : ''
                }
                <Box><br/>{ scheduledBody }</Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box textAlign="right" style={{ margin: '10px 20px 10px 5px' }}>
              <Button className={ classes.btnBranco } onClick={ (props.handleClose) ? props.handleClose : handleClose }>Agora não</Button>
              <Button className={ classes.btnAzulEscuro } onClick={ onFormSubmit }>{ buttonLabel() }</Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={(props.handleClose) ? props.handleClose : handleClose } >
          <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
        </Snackbar>
      </Box>
    </>
  );

}