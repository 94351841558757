import React, { Fragment } from 'react';
import { Paper, Box, makeStyles, Typography, Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({

    titlePacotesMobile: {
        display: 'flex',
        alignItems: 'center',
        /*background: 'url(./img/icon_bronze.png) no-repeat center right',*/
        background: 'url(./img/pacoteMobile/logoMobile.png) no-repeat center right',
        width: '158px',
        height: '43px',
        margin: 'auto'
    },
    titlePacotesBronze: {
        display: 'flex',
        alignItems: 'center',
        /*background: 'url(./img/icon_bronze.png) no-repeat center right',*/
        background: 'url(./img/pacoteBronze/logoBronze.png) no-repeat center right',
        width: '166px',
        height: '43px',
        margin: 'auto'
    },
    titlePacotesPrata: {
        display: 'flex',
        alignItems: 'center',
        background: 'url(./img/pacotePrata/logoPrata.png) no-repeat center right',
        width: '142px',
        height: '43px',
        margin: 'auto'
    },
    titlePacotesOuro: {
        display: 'flex',
        alignItems: 'center',
        background: 'url(./img/pacoteOuro/logoOuro.png) no-repeat center right',
        width: '128px',
        height: '43px',
        margin: 'auto'
    },
    btnSaibaMais: {
        backgroundColor: '#3D3078',
        color: '#fff',
        padding: '13px 26px',
        borderRadius: '4px',
        marginTop: '50px',
        width: '100%',
        "&:hover": {
            backgroundColor: '#01d1fe',
            color: '#fff',
        }
    },
    colorMobile: {
        color: '#000000',
    },
    colorBronze: {
        color: '#AB653B',
    },
    colorPrata: {
        /*color: '#8F8F8F',*/
        color: '#505050'
    },
    colorOuro: {
        color: '#C19D3F',
    },
    linePacotes: {
        margin: '13px 0 17px',
        BorderTop: '1px solid #c8c8c8',
    },
    descrPacotes: {
        top: '1543',
        left: '51px',
        width: '258px',
        height: '110px',        
        justifyContent: 'space-evenly',
        textAlign: 'left',
        font: 'normal normal 20px/28px Roboto',
        letterSpacing: '0px',
    },
    carouselIndicatorsProps: {
        color: 'transparent',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #707070',
        margin: '15px!important',
        "&:hover": {        
            color: 'transparent',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            color: 'transparent',
        }
    },
    carouselActiveIndicatorsProps: {
        color: '#01D1FE',
        width: '16px!important',
        height: '16px!important',
        border: '1px solid #01D1FE',
        margin: '15px!important',
        background: '#01D1FE 0% 0% no-repeat padding-box',
        "&:focus": {
            background: '#01D1FE 0% 0% no-repeat padding-box',
            border: '1px solid #01D1FE',
            color: '#01D1FE',
        },
    },
    carouselNavButtonProps: {
        display: 'block',
        backgroundColor: '#fff!important',
        color: '#01D1FE!important', 
        borderRadius: '0!important',
        opacity: '1!important',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        }
    },
    setas: {
        height: '40px',
        width: '25px'
    },
    tituloPlanos: {
        //color: '#000',
        font: 'normal normal 900 20x/16px Roboto!important',
        fontWeight: 'bold'
    },
}));

export default function CarouselLanding(props) {
    const classes = useStyles();

    const contentMobile = (
        <Fragment>
            <Box height="1060px">
                <Box className={classes.titlePacotesMobile}></Box>
                <hr className={classes.linePacotes} />
                <Typography variant="body2" className={classes.descrPacotes}>
                    <Box className={classes.descrSimplesPacotes}>
                        Ideal para quem está em movimento ficar atualizado em qualquer lugar.
                    </Box>
                    <Box height="52px"></Box>
                    <Box className={classes.descrSimplesPacotes}>
                        <ul>
                            <li>Cotações da B3 em tempo real;</li>
                            <li>Dados macroeconômicos de mais de 20 países;</li>
                            <li>Projeções dos principais indicadores da economia nacional;</li>
                            <li>Notícias em tempo real sobre o mercado nacional e internacional;</li>
                            <li>Acesso ao Jornal Valor Econômico no app;</li>
                            <li>Banco de dados completo das empresas listadas na B3;</li>
                            <li>Acesso somente ao app.</li>
                        </ul>
                    </Box>
                </Typography>                
            </Box>
            <Box><Button className={classes.btnSaibaMais} href="#mobile">Saiba mais</Button></Box>
        </Fragment>
    )
    
    const contentBronze = (
        <Fragment>
            <Box height="1060px">
                <Box className={classes.titlePacotesBronze}></Box>
                <hr className={classes.linePacotes} />
                <Typography variant="body2" className={classes.descrPacotes}>
                    <Box className={classes.descrSimplesPacotes}>
                        Pensado para você, pessoa física que precisa de mais para tomar decisões como um verdadeiro profissional.
                    </Box>
                    <Box height="52px"></Box>
                    <Box className={classes.descrSimplesPacotes}>
                        <ul>
                            <li>Cotações da B3 em tempo real;</li>
                            <li>Dados macroeconômicos de mais de 20 países;</li>
                            <li>Projeções dos principais indicadores da economia nacional;</li>
                            <li>Calendário econômico, eventos corporativos e pagamento de proventos;</li>
                            <li>Simuladores de Renda Fixa;</li>
                            <li>Gráficos com mais de 50 estudos técnicos;</li>
                            <li>Banco de dados completo das empresas listadas na B3;</li>
                            <li>Análise comparativa de indicadores financeiros, múltiplos e muito mais;</li>
                            <li>Notícias em tempo real sobre o mercado nacional e internacional;</li>
                            <li>Acesso ao Terminal e ao App.</li>
                        </ul>
                    </Box>
                </Typography>                
            </Box>
            <Box><Button className={classes.btnSaibaMais} href="#bronze">Saiba mais</Button></Box>
        </Fragment>
    )

    var items = [
        { content: contentBronze, },
        { content: contentMobile, },
    ]

    return (
        <Carousel
            autoPlay="true"
            interval="20000"
            animation="slide"
            fullHeightHover="false"
            navButtonsAlwaysVisible="true"
            indicatorIconButtonProps={{ className: classes.carouselIndicatorsProps }}
            activeIndicatorIconButtonProps= {{ className: classes.carouselActiveIndicatorsProps }}
            navButtonsProps={{ className: classes.carouselNavButtonProps }} 
            PrevIcon={ <img src="./img/icons/angle-left-solid-blue.svg" alt="Prev" className={classes.setas}/> }
            NextIcon={ <img src="./img/icons/angle-right-solid-blue.svg" alt="Next" className={classes.setas}/> }
        >
            { items.map((item, i) => <ContentLanding key={i} item={item} />) }
        </Carousel >
    )
}

export function ContentLanding(props) {

    return (
        <Paper>
            <Box className="containerPacotesPessoaFisicaModile">
                {props.item.content}
            </Box>            
        </Paper>
    )
}