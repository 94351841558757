import { validade } from './ValidationService'

export function setUnknown(stage, cvalue) {
    return validade(stage, cvalue)
}

export function is_validate(state) {
    let validated = null
    let is_error = false
    for (let key in state) {
        let field = state[key]
        validated = validade(field, field.value);
        if (validated.error) {
            state[key].helperText = validated.error
            state[key].error = true
            is_error = true
            return state
        }
    };
    if (is_error) {
        return state
    }
    return false;
}

export function validateForm(state) {
    let validated = null

    for (let key in state) {
        let field = state[key]
        validated = validade(field, field.value);
        if (validated.error) {
            return true
        }
    };
    return false;
}
