import React, { useState, version } from 'react';

// fields
import { Text } from '../../../../components/Fields/text'
import { CheckField } from '../../../../components/Fields/check'
import { SelectFields } from '../../../../components/Fields/select'
import { ButtonSendRequestCustom } from '../../../../components/Fields/button'
import { DateActivation, DateNoRequired } from '../../../../components/Fields/date'

// services
import { Mask } from '../../../../business/MaskService'
import { formConfig } from '../../../../business/ConfigService'
import { validateForm } from '../../../../business/UserService'
//import { GetUserServices } from '../../../../business/ApiService'
import { SV_API_CONTA, VIA_CEP_WS } from '../../../../business/ExternalUrlService'

// libs
import { Typography, Box, withStyles, makeStyles, Button, Grid, Snackbar, TextField, NativeSelect, FormControl, InputLabel, InputBase } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
//import Config from '../../../../utils/config.js';


const useStyles = makeStyles(theme => ({
    middleTitle: {
    	display: 'block',
        color: '#fff',
        fontSize: '19px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D', 
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },    
    btnAzulEscuro: {        
        width: '114px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',        
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnAzulEscuroEditar: {        
        width: '158px',
        height: '50px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    imgBtnposicao: {
        height: '28px',
        width: '28px',
        left: '25px',
        position: 'absolute',
        top: '11px',
    },
    imgBtnEditCliente: {
        //height: '28px',
        //width: '28px',
        //position: 'absolute',
        //top: '21px',
        //left: '18px',
        marginTop: '15px',
        textAlign: 'center'
    },
    txtBtnposicao: {
        position: 'absolute',
        top: '16px',
        left: '65px',
        fontWeight: '400',
        lineHeight: '1.4',
        textAlign: 'center',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#fff',
        textTransform: 'uppercase',
    },
    checkbox: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #707070',        
        borderRadius: '360px',        
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 20,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: 'translateX(15px)',
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 1,
            color: '#7D7D7D',
            "&.Mui-checked": {
                transform: 'translateX(15px)',
                color: '#3D3078',
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
                },
            },
        },        
        "& .MuiSwitch-thumb": {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 20,            
            borderRadius: 16,
            transition: theme.transitions.create(['width'], { duration: 200, }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 0,            
            backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
            boxSizing: 'border-box',
            
        },
    },
    lineFormat: {
    	border: '1px solid #00000026',
		opacity: '1',
    },
    noEditableLabel: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#7B7B7B',
        width: '180px',
        height: '19px',
        marginBottom: '17px',
    },
    noEditableInfo: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        width: '370px',
        height: '19px',
        marginBottom: '17px',
    },
    groupTitle: {
        paddingTop: '30px',
        paddingBottom: '56px',
        width: '580px',
        height: '22px',
        textAlign: 'left',
        font: 'normal normal medium 18px/28px Roboto',
        letterSpacing: '0px',
        color: '#333333',
    },
    executiveTitle: {
        width: '223px',
        height: '29px',
        textAlign: 'left',
        font: 'normal normal bold 24px/29px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    executiveInfo: {
        marginBottom: '10px',
        width: '580px',
        height: '19px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    formTitle: {
        paddingTop: '22px',
        width: '181px',
        height: '28px',
        textAlign: 'left',
        font: 'normal normal bold 23px/28px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    infoAtendimento: {
        //top: '1531px',
        left: '431px',
        width: '442px!important',
        height: '47px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    btnAzulEscuroIconeApenas: {
        width: '50px',
        height: '50px',
        left: '5px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
}));

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #7D7D7D',
    fontSize: 16,
    textAlign: 'left',
    padding: '20px 26px 20px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto'
    ].join(','),
      '&:focus': {
        borderRadius: 2,
        borderColor: '#070026', //'#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
  },
}))(InputBase);

export function FormularioAlterarCliente(props){
    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));

	/*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    const [selectedDate, setSelectedDate] = useState(null);
    const [activationDate, setActivationDate] = useState(null);
    const [initialDate, setInitialDate] = useState(new Date().toLocaleDateString('en-CA'));

    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: 'Usuário atualizado com sucesso.',
        loading: false,
        open: false,
        button: validateForm(conf)        
    });

    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {        
            version.textSnack = error;
            setAlertSeverity('error'); /* SNACKBAR */
        }

        setStage(version);
        setAlertMessage(version.textSnack);
        setOpen(true);
    };

    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
            version.loading = true;
            setStage(version)
        e.preventDefault();

        //submit('PUT', stage, SV_API_CONTA+'/updatePassword', version, callBack);
    };

    const handlePage = (field) => {
        if (stage.cep.id === field.id) {
            let step = (field.value) ? 'block' : 'none'
            let elems = document.getElementsByClassName('fieldsNone');
            for (let i = 0; i < elems.length; i += 1) {
                elems[i].style.display = step;
            }
            getCEP()
        }
    }

    const getCEP = () => {
        if (!stage.cep.value)
            return true

        var xhr = new XMLHttpRequest()
        xhr.timeout = 3000;

        xhr.onreadystatechange = function () {
            let version = Object.assign({}, stage);
            version.loading = false
            if (this.readyState === 4) {

                if (this.status === 200) {
                    let endereco = JSON.parse(this.responseText);

                    version.cep.error = false
                    version.cep.helperText = ''
                    version.logradouro.value = endereco.logradouro;
                    version.bairro.value = endereco.bairro;
                    version.cidade.value = endereco.localidade;
                    version.estado.value = endereco.uf;
                    version.button = validateForm(version);
                    setStage(version)
                }
                else {
                    version.cep.error = false
                    version.cep.helperText = ''
                    setStage(version)
                }
            }
        }

        /* VLRCONT-276 */
        if(stage.cep.value.length >= 9)
        {
            xhr.open('GET', VIA_CEP_WS + stage.cep.value + '/json'); /*'/json/unicode'*/
            xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            xhr.send();
        }
    };

    const buttonLabel = () => {
        return (
            <Typography style={{ font: 'normal normal bold 16px/19px Roboto' }}>Salvar</Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        classes: classes.btnAzulEscuro
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);        
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        handlePage(field)
        setStage(version);
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };

    return (
        <form id={ props.idForm } onSubmit={ onFormSubmit } className="containerForm3" style={{ minHeight: "1650px" }}>
        	<Box className={ classes.titleSpacing }>
        		<Box textAlign="left" className={ classes.titleSpacing }>
                    <Typography className={ classes.formTitle }>Editar usuário</Typography>
                </Box>
                <br/>
                <Box sx={{ height: "650px", width: "100%" }}>
                    <Box textAlign="left">
                        {/*<SelectFields blur={ handleBlur } dados={ GetUserServices() } stage={ stage.descricao } />*/}
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Plano:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>ValorPRO Prata</Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                    <Box className={ classes.groupTitle }>Dados do usuário</Box>
				    <Box textAlign="left">
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Nome:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>Marcos J. Penteado</Box>
                            </Grid>
                        </Grid>
                        <br/>
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.cargo } />
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.departamento } />
                        <Box id="boxOption" className={ classes.boxOption } >
                            <Box>
                                <DateNoRequired
                                    selected={ selectedDate } 
                                    onChange={ date => setSelectedDate(date) }
                                    id="birthDate"
                                    blur={ handleBlur }
                                    stage={ stage.maioridade }
                                />
                            </Box>
                        </Box>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={classes.noEditableLabel}>CPF:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.noEditableInfo}>000.000.000-00</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>E-mail:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>marcos.penteado@email.com</Box>
                            </Grid>
                        </Grid>
                        <br/>
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.emailRecuperacao } />
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.telefone } />
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.celular } />
                    </Box>
                    <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                    <Box className={ classes.groupTitle }>Endereço</Box>
                    <Box textAlign="left">
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>CEP:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>0000-001</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Logradouro:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>Rua ABC</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Número:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>123</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Complemento:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>Condomínio Nárnia</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Bairro:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>Nárnia</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Cidade:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>São Paulo</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item md={4}>
                                <Box className={ classes.noEditableLabel }>Estado:</Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={ classes.noEditableInfo }>SP</Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr><br/>
                    <Box>
                        <Box>
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                                <Grid item>
                                    <img src="/img/icons/ico_info.svg" alt="icon_info"/>
                                </Grid>
                                <Grid item md={10} className={ classes.infoAtendimento }>
                                    Para editar outras informações, favor entrar em contato com nosso atendimento.
                                </Grid>
                            </Grid>
                        </Box>
                        <br/>
                        <Box textAlign="right">
                            <Button className={ classes.btnBranco } href="#/minhaConta/clientes">Cancelar</Button>
                            <ButtonSendRequestCustom stage={ stage.button }>{ button }</ButtonSendRequestCustom>
                        </Box>
                    </Box>
                </Box>
			</Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
        </form>
    )
}