export const VIA_CEP_WS = 'https://viacep.com.br/ws/';
export const POLITICA_PRIVACIDADE = 'https://privacidade.globo.com/privacy-policy/';

// CONSTANTES DE AMBIENTES
const ENDPOINT_DEV = {
	'URL_API': 'https://valorpro.dev.globoi.com',
	'URL_VENDAS': 'https://vendas.dev.valorpro.com.br',
	'URL_OATH': 'https://identity.dev.valorpro.com.br',
	'CLIENT_ID': process.env.REACT_APP_CLIENT_ID_DEV,
	'CLIENT_SECRET': process.env.REACT_APP_CLIENT_SECRET_DEV
}

const ENDPOINT_HML = {
	'URL_API': 'https://valorpro.hml.globoi.com',
	'URL_VENDAS': 'https://vendas.hml.valorpro.com.br',
	'URL_OATH': 'https://identity.hml.valorpro.com.br',
	'CLIENT_ID': process.env.REACT_APP_CLIENT_ID_HML,
	'CLIENT_SECRET': process.env.REACT_APP_CLIENT_SECRET_HML
}

const ENDPOINT_PROD = {
	'URL_API': 'https://valorpro.globo.com',
	'URL_VENDAS': 'https://vendas.api.valorpro.com.br',
	'URL_OATH': 'https://identity.valorpro.com.br',
	'CLIENT_ID': process.env.REACT_APP_CLIENT_ID,
	'CLIENT_SECRET': process.env.REACT_APP_CLIENT_SECRET
}

// DEFINA AQUI QUAL DICIONARIO UTILIZAR
const ENDPOINT = ENDPOINT_PROD;

// ARQUIVOS PDF
export const TERMO_ADESAO = (`${ENDPOINT['URL_API']}/pdf/termos_adesao_valorpro.pdf`);
export const TERMO_USO = (`${ENDPOINT['URL_API']}/pdf/termos_de_uso_valorpro.pdf`);

// ENDPOINTS PYTHON
export const MAIL_API_EXCLUSAO = (`${ENDPOINT['URL_API']}/api/email/send/cancel`);
export const MAIL_API_VENDA = (`${ENDPOINT['URL_API']}/api/email/send/buy`);
export const MAIL_API_TRIAL = (`${ENDPOINT['URL_API']}/api/email/send`);
export const EXT_API_IPLOOKUP = (`${ENDPOINT['URL_API']}/api/iplookup`);
export const EXT_API_DOWNLOAD = (`${ENDPOINT['URL_API']}/api/download`);

// ENDPOINTS SV
export const SV_API_TRIAL = (`${ENDPOINT['URL_VENDAS']}/api/v1/demonstracao/pessoa/fisica`);
export const SV_API_VENDA = (`${ENDPOINT['URL_VENDAS']}/api/v1/venda/site/pessoa/fisica`);
export const SV_API_PACOTES = (`${ENDPOINT['URL_VENDAS']}/api/v1/site`);
export const SV_API_CONTA = (`${ENDPOINT['URL_VENDAS']}/api/v1`);
export const SV_API_EXCLUSAO = (`${ENDPOINT['URL_VENDAS']}/api/v1`);

// IDENTIFY
export const ID_API_OATH = ENDPOINT['URL_OATH'];
export const API_CLIENT_ID = ENDPOINT['CLIENT_ID'];
export const API_CLIENT_SECRET = ENDPOINT['CLIENT_SECRET'];