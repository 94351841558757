import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
        if (this.props.location.pathname === "/servicos") {
            window.scrollTo(0, document.getElementById('blcServicos').offsetTop);
        }
        if (this.props.location.pathname === "/pacotes") {
            window.scrollTo(0, document.getElementById('blcPacotes').offsetTop);
        }
        if (this.props.location.pathname === "/downloads") {
            window.scrollTo(0, document.getElementById('blcDownloads').offsetTop);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);