import React from 'react';
import Axios from 'axios';
import MD5 from 'js-md5';

import { OAuth2Client } from '@badgateway/oauth2-client';
import { SV_API_CONTA, API_CLIENT_ID, API_CLIENT_SECRET, ID_API_OATH } from '../business/ExternalUrlService';

// svGetAccessToken
async function GetAccessToken()
{
    // https://github.com/badgateway/oauth2-client#readme
    var client = new OAuth2Client({
            clientId: API_CLIENT_ID,
            clientSecret: API_CLIENT_SECRET,
            tokenEndpoint: "/connect/token",
            server: ID_API_OATH
            //client_credentials
        });

    var token = await client.clientCredentials();
    return token;
}

export async function AxiosSubmitLogin(url, data, state, callback)
{
    var json = {}
    Object.entries(data).forEach( element => {
        if (typeof element[0] !== "undefined"){
            json[ element[0] ] = element[1].value;
        }
    });

    var token = await GetAccessToken();

    const instance = Axios.create({
        timeout: 5000,
        baseURL: SV_API_CONTA,
        headers: {
            "Accept": "*/*",
            "Content-type": "application/json; charset=utf-8", 
            "Authorization": "Bearer " + token["accessToken"]
        }
    });

    var xhr = await instance.post(url, json).then((response) => { return response }).catch((err) => { return err.response });

    if (xhr.request.readyState === 4)
    {
        let sessionKeysAllowed = ['idUsuarioConta', 'username', 'isUserMaster', 'loginUserMaster'];

        let expiresAt = new Date();
            expiresAt = ( new Date(expiresAt.getDate() + parseInt(token["expiresAt"])) );

        sessionStorage.setItem('token', token["accessToken"]);
        sessionStorage.setItem('expires_at', expiresAt);

        state.erro = '';
        switch (xhr.status)
        {
            case 200:
                sessionKeysAllowed.forEach(el => { sessionStorage.setItem(el, xhr.data.message[el]); });
                let route = (xhr.data.message.isUserMaster === true) ? '#/minhaConta/clientes' : '#/minhaConta/meusDados';
                window.location.replace(route);
                break;
            case 201:
                break;
            case 400:
                state.erro = xhr.data.message;
                break;
            case 401:                
                sessionKeysAllowed.forEach(el => { sessionStorage.setItem(el, xhr.data.message[el]); });
                window.location.replace('#/recuperarSenha');
                break;
            case 404:
                state.erro = xhr.data.message;
                break;
            case 409:
                msg_erro()
                break;
            case 429:
                state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                break;
            default:
                state.erro = "O Servidor está indisponivel, tente mais tarde :("
        }

        callback(state.erro);
    }

    function msg_erro() {
        
        let parse = {}
        let result = xhr.data;
        
        if (typeof result === 'string') { result = JSON.parse(result) }

        if (result['erro']) { parse = result['erro'] }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`
        });
    }
    
}

export async function AxiosChangePassword(url, data, state, callback)
{
    var json = {}
    Object.entries(data).forEach( element => {
        if (typeof element[0] !== "undefined"){
            json[ element[0] ] = element[1].value;
        }
    });

    const instance = Axios.create({
        timeout: 5000,
        baseURL: SV_API_CONTA,
        headers: {
            "Accept": "*/*",
            "Content-type": "application/json; charset=utf-8", 
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });

    var xhr = await instance.put(url, json).then((response) => { return response }).catch((err) => { return err.response });

    if (xhr.request.readyState === 4)
    {

        state.erro = '';
        switch (xhr.status)
        {
            case 200:
                let route = (sessionStorage.getItem("isUserMaster") === "true") ? '#/minhaConta/clientes' : '#/minhaConta/meusDados';
                window.location.replace(route);
                break;
            case 201:
                break;
            case 400:
                state.erro = xhr.data.message;
                break;
            case 401:
                state.erro = xhr.data.message;
                window.location.replace('#/recuperarSenha');
                break;
            case 404:
                state.erro = xhr.data.message;
                break;
            case 409:
                msg_erro()
                break;
            case 429:
                state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                break;
            default:
                state.erro = "O Servidor está indisponivel, tente mais tarde :("
        }

        callback(state.erro);
    }

    function msg_erro() {
        
        let parse = {}
        let result = xhr.data;
        
        if (typeof result === 'string') { result = JSON.parse(result) }

        if (result['erro']) { parse = result['erro'] }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`
        });
    }
}

export async function AxiosSendNewpassord(url, data, state, callback)
{
    var json = {}
    Object.entries(data).forEach( element => {
        if (typeof element[0] !== "undefined"){
            json[ element[0] ] = element[1].value;
        }
    });

    var token = await GetAccessToken();

    const instance = Axios.create({
        timeout: 5000,
        baseURL: SV_API_CONTA,
        headers: {
            "Accept": "*/*",
            "Content-type": "application/json; charset=utf-8", 
            "Authorization": "Bearer " + token["accessToken"]
        }
    });

    var xhr = await instance.post(url, json).then((response) => { return response }).catch((err) => { return err.response });

    if (xhr.request.readyState === 4)
    {

        state.erro = '';
        switch (xhr.status)
        {
            case 200:                
            case 201:
                break;
            case 400:
                state.erro = xhr.data.message;
                break;
            case 401:
                state.erro = xhr.data.message;
                break;
            case 404:
                state.erro = xhr.data.message;
                break;
            case 409:
                msg_erro()
                break;
            case 429:
                state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                break;
            default:
                state.erro = "O Servidor está indisponivel, tente mais tarde :("
        }

        callback(state.erro);
    }

    function msg_erro() {
        
        let parse = {}
        let result = xhr.data;
        
        if (typeof result === 'string') { result = JSON.parse(result) }

        if (result['erro']) { parse = result['erro'] }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`
        });
    }
}

export async function AxiosSubmitScheduled(url, data, state, callback)
{
    var json = {}
    Object.entries(data).forEach( element => {         
        if (typeof element[0] !== "undefined"){
            if (element[1] !== "null")
            {
                json[ element[0] ] = element[1];
            }            
        }
    });

    const instance = Axios.create({
        timeout: 5000,
        baseURL: SV_API_CONTA,
        headers: {
            "Accept": "*/*",
            "Content-type": "application/json; charset=utf-8", 
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });


    var xhr = await instance.post(url, json).then((response) => { return response }).catch((err) => { return err.response });

    if (xhr.request.readyState === 4)
    {

        state.erro = '';
        switch (xhr.status)
        {
            case 200:                
            case 201:
                break;
            case 400:
                state.erro = xhr.data.message;
                break;
            case 401:
                state.erro = xhr.data.message;
                break;
            case 404:
                state.erro = xhr.data.message;
                break;
            case 409:
                msg_erro()
                break;
            case 429:
                state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                break;
            default:
                state.erro = "O Servidor está indisponivel, tente mais tarde :("
        }

        callback(state.erro);
    }

    function msg_erro() {
        
        let parse = {}
        let result = xhr.data;
        
        if (typeof result === 'string') { result = JSON.parse(result) }

        if (result['erro']) { parse = result['erro'] }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`
        });
    }
}

export async function AxiosSubmitClient(url, data, state, callback)
{
    var json = {}
    Object.entries(data).forEach( element => {
        //console.log(element[0], element[1])
        if (typeof element[1] !== "undefined"){
            json[ element[0] ] = (element[1] !== "") ? element[1] : null;
        }
    });

    const instance = Axios.create({
        timeout: 5000,
        baseURL: SV_API_CONTA,
        headers: {
            "Accept": "*/*",
            "Content-type": "application/json; charset=utf-8", 
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });

    var xhr = await instance.post(url, json).then((response) => { return response }).catch((err) => { return err.response });

    if (xhr.request.readyState === 4)
    {

        state.erro = '';
        switch (xhr.status)
        {
            case 200:
            case 201:
                break;
            case 400:
                state.erro = xhr.data.message;
                break;
            case 401:
                state.erro = xhr.data.message;
                break;
            case 404:
                state.erro = xhr.data.message;
                break;
            case 409:
                msg_erro()
                break;
            case 429:
                state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                break;
            default:
                state.erro = "O Servidor está indisponivel, tente mais tarde :("
        }

        callback(state.erro);
    }

    function msg_erro() {
        
        let parse = {}
        let result = xhr.data;
        
        if (typeof result === 'string') { result = JSON.parse(result); }

        if (result['erro']) { parse = result['erro'] }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`;
        });
    }

}


export async function verifyTokenTime()
{
    var now = new Date();
    var sessionDate = new Date(sessionStorage.getItem("expires_at"));
    var diff = (sessionDate.getTime() - now.getTime());
    
    if (diff <= 5000)
    {
        /*
        var token = await svGetAccessToken();
        
        let d = new Date() //.toLocaleString("pt-BR", { dateStyle: "short", timeStyle: "long" });
            d = (new Date(d.getDate() + parseInt(token["expiresAt"])));
        
        sessionStorage.setItem('token', token["accessToken"]);
        sessionStorage.setItem('expires_at', d);
        */
        sessionStorage.clear();
        window.location.replace('#/minhaConta');
    }
}

//svCheckPassword
export function CheckPasswordsInputs(pwd1, pwd2)
{
    let status = false;    

    if ((pwd2 !== pwd1) || (pwd2 === "") || (pwd2 === undefined)) { status = true; }
    if ((pwd1 !== pwd2) || (pwd1 === "") || (pwd1 === undefined)) { status = true; }

    return status
}

//svGetIpAdress
export function GetIpAdress(verb, data, url)
{
    var json = JSON.stringify(data);
    var xhr = new XMLHttpRequest();
        xhr.timeout = 15000;
        xhr.open(verb, url, true);
        xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
        xhr.onreadystatechange = function(){
            if (this.readyState === 4) {
                let result = JSON.parse(xhr.responseText);
                return result['message'];
            }
        }
    xhr.send(json);
}

//svCreateApiCode
export function CreateApiCode(data)
{
    // https://www.npmjs.com/package/js-md5
    var key = (data + 101).toString();
    var hash = MD5.create();
        hash.update(key);

    return hash.hex();
}

// ************************************************************* GENERIC ENDPOINTS

export function submit(verb, data, url, state, callback, svMode=false)
{
    var json = (svMode) ? JSON.stringify(data) : JSON.stringify(getData(data));
    var xhr = new XMLHttpRequest();
        xhr.open(verb, url, true);
        xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
    
        xhr.onreadystatechange = function () {
            if (this.readyState === 4)
            {    
                state.erro = '';
                switch (xhr.status)
                {
                    case 200:
                    case 201:
                        break;
                    case 203:
                    case 204:
                    case 400:
                        break;
                    case 404:
                        let result = JSON.parse(xhr.responseText)
                        state.erro = result.message
                        break;
                    case 409:
                        msg_erro()
                        break;
                    case 429:
                        state.erro = "Numero de tentativas excedidas, tente mais tarde :("
                        break;
                    default:
                        state.erro = "O Servidor está indisponivel, tente mais tarde :("
                }

                callback(state.erro)
            }
        };
        
        xhr.send(json);

    function msg_erro() {
        let result = JSON.parse(xhr.responseText)
        let parse = {}
        if (typeof result === 'string') {
            result = JSON.parse(result)
        }

        if (result['erro']) {
            parse = result['erro']
        }

        Object.keys(parse).forEach(function (item) {
            state.erro += `${parse[item]}\n`
        });
    }

    function getData (state) {
        let data = {};

        Object.keys(state).forEach(function (item) {
            if(item) {
                data[state[item].id] = state[item].value;
            }
        })

        return data;
    }
}