import React from "react";

function GetNetworkStats() {
    const [networkStats, setNetworkStats] = React.useState('');
    var accessInfo = {}

    const fetchNetwork = async () => {
        try {
            const response = await fetch('https://api.ipify.org');
            const data = await response.text();
            setNetworkStats(data);
        } catch (error) {
            console.log('Failed to get IP', error);
        }
    }
    
    const fetchNetworkLocation = async () => {
        try {
            const response = await fetch(`https://api.country.is/${networkStats}`);
            const data = await response.text();
            setNetworkStats(data);
        } catch (error) {
            console.log('Failed to get IP stats', error);
        }
    }

    React.useEffect(() => { fetchNetwork(); });
    React.useEffect(() => { fetchNetworkLocation(); });

    if (networkStats.indexOf('country') > -1)
    {
        var geo = JSON.parse(networkStats);               
            accessInfo['platform'] = navigator.platform;
            accessInfo['vendor'] = navigator.vendor;
            accessInfo['ip'] = geo.ip;
            accessInfo['country'] = geo.country;
            accessInfo['appName'] = navigator.appName;
            accessInfo['formType'] = "";
            accessInfo['plan'] = "";
    }
    
    return accessInfo;    
}

export default GetNetworkStats;
