import React from 'react';
import Axios from 'axios';

// libs excel
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

// fields
import { Text } from '../../../components/Fields/text'
import { SelectFields } from '../../../components/Fields/select'
import { MyAccountClientDialog } from '../../../components/Fields/modalDialog'

// services
import { Mask } from '../../../business/MaskService'
import { formConfig } from '../../../business/ConfigService'
import { validateForm } from '../../../business/UserService'
import { SV_API_CONTA } from '../../../business/ExternalUrlService'
import { optionStatusCliente } from '../../../business/OptionsService'

// libs
import { Typography, Box, makeStyles, Button, Link, Grid, Switch, Tooltip, Snackbar, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid, ptBR } from '@material-ui/data-grid';
//import { ptBR as corePtBR } from '@material-ui/core/locale';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles(theme => ({
    middleTitle: {
    	display: 'block',
        color: '#fff',
        fontSize: '19px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D', 
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    esqueceuSenhaLink: {
        width: '194px',
        height: '19px',        
        textAlign: 'center',
        font: 'normal normal 900 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#3D3078!important',
        textTransform: 'uppercase',
        opacity: '1',
        '&:hover': {
            textDecoration: 'underline!important',
        },
        // ['@media (max-width:980px)']: {
        //     width: '90%',
        // }
    },
    btnAzulEscuroNovo: {        
        width: '230px',
        height: '52px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        '&:hover': {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnAzulEscuroExport: {        
        width: '246px',
        height: '52px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        '&:hover': {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnAzulEscuroIconeApenas: {
        width: '52px',
        height: '52px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        '&:hover': {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        '&:hover': {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    imgBtnposicao: {
        height: '28px',
        width: '28px',
        position: 'absolute',
        top: '12px',
        left: '25px',
    },
    imgBtnposicaoIcone: {
        height: '28px',
        width: '28px',
        position: 'absolute',
        top: '12px',
        left: '18px',
    },
    imgBtnEditCliente: {
        //height: '28px',
        //width: '28px',
        //position: 'absolute',
        //top: '21px',
        //left: '18px',
        marginTop: '15px',
        textAlign: 'center'
    },
    txtBtnposicao: {
        position: 'absolute',
        top: '16px',
        left: '55px',
        fontWeight: '400',
        lineHeight: '1.4',
        textAlign: 'center',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#fff',
        textTransform: 'uppercase',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        animationDuration: '550ms',
    },
    
    checkbox: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #707070',        
        borderRadius: '360px',        
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 20,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(15px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 1,
            color: '#7D7D7D',
            '&.Mui-checked': {
                transform: 'translateX(15px)',
                color: '#3D3078',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
                },
            },
        },        
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 20,            
            borderRadius: 16,
            transition: theme.transitions.create(['width'], { duration: 200, }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 0,            
            backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
            boxSizing: 'border-box',
            
        },

    }
}));

export function FormularioListarClientes(props){
    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));

	/*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    /*PACOTES*/
    const [services, setServices] = React.useState([]);
    const GetServices = () => {
        React.useEffect(() => {
            async function fetchData(){

                var idUsuarioConta = parseInt(sessionStorage.getItem('idUsuarioConta'));
                var instance = Axios.create({
                    baseURL: SV_API_CONTA,
                    timeout: 5000, 
                    headers: { 
                        "Accept": "*/*",
                        "Content-type": "application/json; charset=utf-8", 
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const r =  await instance.get(`/master/services?idUsuarioConta=${idUsuarioConta}`).then((response) => { return response.data.message }).catch((err) => { return err.response.data.message });
                setServices(r);
            }
            
            fetchData();

        }, []);

        var servicesArray = [];
        for(let i=0; i < services.length; i++)
        {
            servicesArray.push({ key: services[i].idPackage, value: services[i].packageName });
        }

        /* caso contenha dados realiza a ordem alfabetica */
        if (servicesArray.length > 0)
        {
            const afterSorting = servicesArray.sort((first, second) => {
                if (first.value.toUpperCase() < second.value.toUpperCase()) return -1;
                if (first.value.toUpperCase() > second.value.toUpperCase()) return 1;
                return 0;
            });

            afterSorting.unshift({ key: 0, value: 'Todos'});
            return afterSorting;
        } 
        else
        {
            servicesArray.unshift({ key: 0, value: 'Todos'});
            return servicesArray;
        }
    }
    
    /*DATAGRID*/
    const [rows, setRows] = React.useState([]);
    const [rowsBck, setRowsBck] = React.useState([]);
    const GetClients = () => {
        React.useEffect(() => {
            async function fetchData(){

                var idUsuarioConta = parseInt(sessionStorage.getItem('idUsuarioConta'));
                var instance = Axios.create({
                    baseURL: SV_API_CONTA,
                    timeout: 5000,                    
                    headers: { 
                        "Accept": "*/*",
                        "Content-type": "application/json; charset=utf-8", 
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const r =  await instance.get(`/master/users?idUsuarioConta=${idUsuarioConta}&page=1&limit=3000`).then((response) => { return response.data.message }).catch((err) => { return err.response.data.message });
                setRows(r);
                setRowsBck(r);
            }

            fetchData();

        }, []);

        const cols = [
            { 
                field: 'status', 
                renderHeader: () => (
                    <>
                        <strong>Status&nbsp;</strong>
                        <Tooltip title='Use para ativar ou desativar acesso dos usuários'>
                            <img src='img/icons/ico_help.svg' style={{ position: 'relative', top: '2px' }} alt='help icon'/>
                        </Tooltip>
                    </>
                    
                ), 
                type: 'string', 
                width: 110, 
                renderCell: (params: GridRenderCellParams<any, String>) => ( checkBox(params) )  
            }, 
            { field: 'nome', headerName: 'Nome', type: 'string', width: 250 },
            { field: 'login', headerName: 'Login', type: 'string', width: 250 },
            { field: 'descricao', headerName: 'Pacote', type: 'string',width: 250 },
            { 
                field: 'dtInicio', 
                headerName: 'Data de início', 
                type: 'string', 
                width: 170, 
                renderCell: (params: GridRenderCellParams<any, String>) => ( formatDate(params) ) 
            },
            { 
                field: 'dtFim', 
                headerName: 'Data de término', 
                type: 'string', 
                width: 170, 
                renderCell: (params: GridRenderCellParams<any, String>) => ( formatDate(params) ) 
            },
            { 
                field: 'edit', 
                headerName: '', 
                width: 60, 
                renderCell: (params: GridRenderCellParams<any, String>) => ( editClient(params) ) 
            }
        ];

        return (
            <DataGrid
                getRowId={ (row) => row.id }
                rows={ (rows) ? rows : [] }
                columns={ cols } 
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }} 
                density='compact' 
                /*pageSizeOptions={ [5] } */
                /*loading={rows.length === 0}*/
                disableRowSelectionOnClick 
                disableMultipleRowSelection 
                disableColumnFilter 
                style={{ border: 0 }} 
                rowHeight={ 55 }                 
                localeText={ ptBR.props.MuiDataGrid.localeText }
                /*rowsPerPageOptions={[10]}*/
                checkboxSelection={ false }
            /> 
        );
    }

    const checkBox = (cliente) => {

        if (cliente.row[cliente.field] === 1)
        {
            return (
                <Box style={{ position: 'relative', left: '10px' }}>
                    <Switch defaultChecked size='small' className={ classes.checkbox } id={ cliente.row.login } 
                        onChange={ (handleChange, checkStatus) } change={ handleChange } defaultValue={ true } 
                        value={ `${cliente.row.idAliasPackage};${cliente.row.dtInicio};${cliente.row.dtFim}` }
                    />
                </Box>
            )
        }
        else if (cliente.row[cliente.field] === 2)
        {
            return (
                <Tooltip title='Solicitação em processamento'>
                    {/*<CircularProgress disableShrink size={23} style={{ position: 'relative', left: '18px', color: '#3D3078'}} />*/}
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'relative', left: '21px', height: "23" }} viewBox="0 0 384 512">
                        <path fill="#3d3078" d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64l0 11c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437l0 11c-17.7 0-32 14.3-32 32s14.3 32 32 32l32 0 256 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-11c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1l0-11c17.7 0 32-14.3 32-32s-14.3-32-32-32L320 0 64 0 32 0zM96 75l0-11 192 0 0 11c0 25.5-10.1 49.9-28.1 67.9L192 210.7l-67.9-67.9C106.1 124.9 96 100.4 96 75z"/>
                    </svg>
                </Tooltip>
            )
        }
        else
        {
            return (
                <Box style={{ position: 'relative', left: '10px' }}>
                    <Switch size='small' className={ classes.checkbox } id={ cliente.row.login } 
                        onChange={ (handleChange, checkStatus) } change={ handleChange } defaultValue={ false } 
                        value={ `${cliente.row.idAliasPackage};${cliente.row.dtInicio};${cliente.row.dtFim}` }
                    />
                </Box>
            )
        }
    }

    const editClient = (cliente) => {
        //https://flowbite.com/icons/
        let id = `#/minhaConta/exibirCliente/${cliente.row.idUsuarioConta}/${cliente.row.idAliasPackage}`;
        let login = `Dados de ${cliente.row.login}`;
        return (
            <Link href={id} underline='none'>
                <Tooltip title={ login }>
                    {/*<img src='img/icons/ico_edit_blue.svg' alt="edit icon" style={{ position: "relative", top: "7px", left: "5px" }}/>*/}
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" 
                        viewBox="0 0 24 24" style={{ color: "#3D3078", position: "relative", top: "7px", left: "8px" }}
                    >
                      <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                      <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                    </svg>
                </Tooltip>
            </Link>
        );
    }

    const formatDate = (cliente) => {
        
        let row = "";
        if (cliente.row[cliente.field] !== null)
        {
            let clientDtFromApi = (cliente.row[cliente.field].split("T")[0]).split("-");
            let dateFormated = clientDtFromApi.reverse().join("/");
            let dateCompare = parseInt(clientDtFromApi.reverse().join(""));
            let id = `dt_inicio_${cliente.field}`;

            /* utiliza flag para data futura */
            let today = parseInt((new Date().toLocaleString("en-ZA").split(",")[0]).replace(/\//g, ""));
            
            /* monta a exibicao de icone para datas futuras */
            row = (dateCompare > today) ? (<Tooltip title="Data agendada"><img src="img/icons/ico_calendar_gray.svg" style={{ position: "relative", top: "-1px", left: "5px" }} alt="calendar icon"/></Tooltip>) : <Box style={{ width: "12px" }}></Box>;
            row = (<>{ row }<span style={{ position: "relative", right: "-12px"}}>{ dateFormated }</span></>);
        }

        return row;
    }

    /* pesquisa */
    const exibirPesquisa = (event) => {
       let div = document.getElementsByClassName("grids")
       for (let i=0; i < div.length; i++)
       {
            div[i].style.display = (div[i].style.display === "" || div[i].style.display === "none") ? "block" : "none";
       }
    }

    /* trata o switch de ativar e desativar cliente */
    const checkStatus = async (field) => {
        let version = Object.assign({}, stage);
            version[field.id] = field;

            version.titleModal = (version[field.id].target.checked) ? "ActiveModal" : "InactiveModal";
            version.textModal = version[field.id].target.id;
            version.value = version[field.id].target.value;
            version.button = validateForm(version);

            version.loading = false;
            version.open = true;

        setStage(version);
    }
    
    const filters = (field) => {
        /* consulta novamente todos os inputs */
        let version = Object.assign({}, stage);
            version[field.id] = field;
            version.button = validateForm(version);
        setStage(version);        

        /* parametros de validacao */
        const status = { "Ativado": 1, "Desativado": 0}
        const versions = [ "status", "descricao" ];
        let r = [];

        rowsBck.forEach(colName => {            
            let selectValues = [];
            let rowsSelectedColumns = [];
            versions.forEach(inputName => {
                if(version[inputName].value !== "" && version[inputName].value !== "Todos")
                {
                    /* alterna o valor do status para numerico */
                    let val = (inputName === "status") ? status[version[inputName].value] : version[inputName].value;
                    
                    /* realiza a comparacao de campos */
                    if (colName[inputName] === val)
                    {
                        rowsSelectedColumns.push(colName[inputName])
                    }

                    /* grava os valores encontrados para comparacao */
                    selectValues.push(val);
                }                
            });

            if(rowsSelectedColumns.join("_") === selectValues.join("_"))
            {
                /* realiza a busca pelo campo livre */
                if(version["busca"].value.length >= 3)
                {
                    let seek = (`${ colName["login"] }_${ colName["nome"] }`).toLowerCase();
                    let input = (version["busca"].value).toLowerCase();

                    if(seek.indexOf(input) !== -1)
                        r.push(colName);
                }
                else
                {
                    r.push(colName);
                }
            }
        });

        setRows(r);        
    }

    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: "Ação realizada com sucesso.",
        button: validateForm(conf)        
    });

    // const callBack = (error) => {
    //     let version = Object.assign({}, stage)
        
    //     if (error) {        
    //         version.textSnack = error;
    //         setAlertSeverity("error"); /* SNACKBAR */
    //     }

    //     setStage(version);
    //     setAlertMessage(version.textSnack);
    //     setOpen(true);
    // };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };

    const ExportExcel = ({ excelData, fileName }) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileDate = (new Date().toLocaleString("en-CA")).split(",")[0];
        const fileExtension = ".xlsx";

        const exportToExcel = async () => {
            
            for(var i=0; i < excelData.length; i++)
            {
                excelData[i]["status"] = (excelData[i]["status"] === 1) ? "Ativado" : "Desativado";
                delete excelData[i]["idUsuarioConta"];
            }

            const ws = XLSX.utils.json_to_sheet(excelData);

            /* formatando nome das colunas */
            ws["A1"]["v"] = ws["A1"]["v"].toUpperCase();
            ws["B1"]["v"] = ws["B1"]["v"].toUpperCase();
            ws["C1"]["v"] = ws["C1"]["v"].toUpperCase();
            ws["D1"]["v"] = "PACOTE";
            ws["E1"]["v"] = "DATA INÍCIO";
            ws["F1"]["v"] = "DATA TÉRMINO";

            const wb = { Sheets: { "data": ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileDate + fileExtension);
        }

        return (
            <Button stage={stage.button} className={classes.btnAzulEscuroExport}>
                <Typography variant="body2" component={ 'span' }>
                    <img src="img/icons/ico_export_white.svg" className={classes.imgBtnposicao} alt="export icon"/>
                    <span className={classes.txtBtnposicao} onClick={ (e) => exportToExcel(fileName) }>&nbsp;&nbsp;&nbsp;Exportar Excel</span>
                </Typography>
            </Button>
        )
    }

    return (
        <form id={ props.idForm } className="containerForm2">
        	<Box className={ classes.titleSpacing }>
        		<Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
        			<Grid item sm={7}>
        				<Button className={ classes.btnAzulEscuroIconeApenas } onClick={exibirPesquisa}>
        					<Typography variant="body2">
                                <img src="img/icons/ico_filter_white.svg" className={ classes.imgBtnposicaoIcone } alt="filter icon"/>
                				<span className={ classes.txtBtnposicao }>&nbsp;</span>
            				</Typography>
        				</Button>
        			</Grid>
        			<Grid item>
                        <Box>
                            <Button href="#/minhaConta/cadastrarCliente" className={ classes.btnAzulEscuroNovo } >
                                <Typography variant="body2">                                    
                                    <img src="img/icons/ico_new_user_white.svg" className={ classes.imgBtnposicao } alt="new_user icon"/>
                                    <span className={ classes.txtBtnposicao }>&nbsp;&nbsp;&nbsp;Novo usuário</span>
                                </Typography>
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <ExportExcel excelData={ rowsBck } fileName={ "ValorPRO-" }/>
                        </Box>
        			</Grid>
        		</Grid>
                <br/>
                <Grid container spacing={1} direction="row" justifyContent="flex-start">
                    <Grid item xs={2} className="grids" style={{ display: "none" }}>
                        <SelectFields dados={ optionStatusCliente } blur={ (handleBlur, filters) } change={ (handleChange, filters) } stage={ stage.status }/>
                    </Grid>
                    <Grid item sm={3} className="grids" style={{ display: "none" }}>
                        <Text
                            blur={ (handleBlur, filters) } 
                            change={ (handleChange, filters) }
                            stage={ stage.busca }
                            variant="outlined"
                            label="Pesquisar"
                        />
                    </Grid>
                    <Grid item sm={2} className="grids" style={{ display: "none" }}>
                        <SelectFields dados={ GetServices() } blur={ (handleBlur, filters) } change={ (handleChange, filters) } stage={ stage.descricao } />
                    </Grid>
                </Grid>                
			</Box>
            <Box sx={{ height: "650px", width: "100%" }}>{ GetClients() }</Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
            <MyAccountClientDialog id="formModalClientStatus" title={stage.titleModal} style={{ display: 'none' }} handleOpen={ stage.open } handleClose={ handleClose } text={ stage.textModal } value={ stage.value }/>
        </form>
    )

}