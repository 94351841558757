import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Typography, Box, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '1.5rem!important',
        textTransform: 'uppercase',
    },
    abaAccordion: {
        backgroundColor: '#3D3078',
        color: '#fff',
    },
    iconArrow: {
        color: '#fff',
        fontSize: '3rem',
        padding: '0',

    }
}));

export function SimpleAccordion() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.iconArrow} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.abaAccordion}
                >
                    <Typography variant="subtitle1" className={classes.heading}>Notícias</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.boxServicos}>
                        <Box className={classes.txtServicos}>
                            <Typography variant="h2">Notícias</Typography>
                            <Typography variant="subtitle1">Acompanhar as mudanças fica muito mais fácil quando se está um passo à frente.</Typography>
                            Notícias exclusivas, furos e bastidores em primeira mão sobre os principais setores do mercado nacional, movimentações do cenário global, atualizadas sobre fatos internacionais que envolvem economia e política, além das movimentações no mercado de câmbio, juros e bolsa.
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.iconArrow} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.abaAccordion}
                >
                    <Typography variant="subtitle1" className={classes.heading}>Cotações</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.boxServicos}>
                        <Box className={classes.txtServicos}>
                            <Typography variant="h2">Cotações</Typography>
                            <Typography variant="subtitle1">Cotações do Brasil e do mundo na tela do seu computador ou na palma de sua mão.</Typography>
                            São cotações da B3 e das principais Bolsas de Valores internacionais em tempo real* ou em delay. Além disso, o <strong>Valor PRO</strong> traz dados macroeconômicos de 27 países, informações sobre os mercados de ações, moedas, taxas de juros e de crédito, derivativos, commodities, fundos e renda fixa.<br /><br />
                            <span className={classes.fontStyleItalic}>*pode envolver custo adicional ou depender do pacote contratado.</span>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.iconArrow} />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    className={classes.abaAccordion}
                >
                    <Typography variant="subtitle1" className={classes.heading}>Empresas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.boxServicos}>
                        <Box className={classes.txtServicos}>
                            <Typography variant="h2">Empresas</Typography>
                            <Typography variant="subtitle1">Precisão na busca e profundidade nos resultados e análises.</Typography>
                            Completo banco de dados com mais de 8 mil empresas brasileiras, tanto de capital aberto quanto fechado, com indicadores fundamentalistas, análises comparativas e muito mais. Uma ferramenta para você realizar buscas, analisar e comparar empresas e tomar decisões com agilidade.
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            {
            /* VLRCONT-168
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.iconArrow} />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                    className={classes.abaAccordion}
                >
                    <Typography variant="subtitle1" className={classes.heading}>Política</Typography>
                </AccordionSummary>
                
                <AccordionDetails>
                    <Box className={classes.boxServicos}>
                        <Box className={classes.txtServicos}>
                            <Typography variant="h2">Política</Typography>
                            <Typography variant="subtitle1">Informações que definem os rumos da economia e impactam os seus negócios.</Typography>
                            Recurso exclusivo do <strong>Valor PRO</strong> para você acompanhar os projetos que tramitam no Congresso, Ministérios, decisões das principais agências reguladoras e as probabilidades de projetos se transformarem em lei. Tudo em primeira mão.
                        </Box>
                    </Box>
                </AccordionDetails>                
            </Accordion>
            */
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.iconArrow} />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                    className={classes.abaAccordion}
                >
                    <Typography variant="subtitle1" className={classes.heading}>Ferramentas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.boxServicos}>
                        <Box className={classes.txtServicos}>
                            <Typography variant="h2">Ferramentas</Typography>
                            <Typography variant="subtitle1">Uma visão completa para o sucesso de seus investimentos.</Typography>
                            Visualize o movimento das corretoras, busque por ativos e notícias, defina alertas, acompanhe a curva de juros, consulte a base histórica, crie gráficos com estudos técnicos, além de aplicar filtros personalizados e exportar para o Excel. Também é possível acessar mais de 17 mil fundos de investimentos e ter uma visão em profundidade com comparação e gráficos.
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}