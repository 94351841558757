export function url(step) {
    let url = ''
    switch (step) {
        // ROTAS PADRÕES
        case 0:
            url = '/'
            break;
        case 1:
            url = '/quemSomos'
            break;
        case 2:
            url = '/servicos'
            break;
        case 3:
            url = '/faleConosco'
            break;
        case 4:
            url = '/helpweb'
            break;
        case 5:
            url = '/downloads'
            break;
        // ROTAS PACOTES - VCE-95
        case 20: 
            url = '/pacotes'
            break;
        case 21:
            url = '/pacotesLista'
            break;            
        case 22:
            url = '/mobile'
            break;
        case 23:
            url = '/bronze'
            break;
        case 24:
            url = '/prata'
            break;
        case 25:
            url = '/ouro'
            break;
        // ROTAS DEMONSTRAÇÃO - VCE-95
        case 40:
            url = '/demonstracaoOuro'
            break;
        case 41:
            url = '/demonstracaoPrata'
            break;
        /*
        case 42:
            url = '/demonstracaoBronze'
            break;
        */
        case 43:
            url = '/demonstracaoMobile'
            break;
        // ROTAS ADESÃO - VCE-95
        case 60:
            /* venda-pacote-id1, IdReferencia OURO */
            url = '/dadd7ee40b421d187c015322bdbee5b6'
            break;
        case 61:
            /* venda-pacote-id2, IdReferencia PRATA */
            url = '/1ed4bbf071e3ffd1f3e1e1a2a3e93c74'
            break;
        case 62:
            /* venda-pacote-id3, IdReferencia BRONZE */
            url = '/5ea73f46574f90a83a8212479800ffd1'
            break;
        case 63:
            /* venda-pacote-id4, IdReferencia MOBILE */
            url = '/a8f4cb5ce8a8fad3106fad9a5fa67373'
            break;
        case 96:
            url = '/ExclusaoDeDados'
            break;
        case 97:
            url = '/VersionPage'
            break;
        case 98:
            url = '/LandingPage'
            break;
        case 99:
            url = '/page-404'
            break;
        case 200:
            url = '/minhaConta'
            break;
        case 210:
            url = '/solicitarSenha'
            break;            
        case 211:
            url = '/recuperarSenha'
            break;
        case 212:
            url = '/minhaConta/alterarSenha'
            break;
        case 230:
            url = '/minhaConta/clientes'
            break;
        case 231:
            url = '/minhaConta/cadastrarCliente'
            break;
        case 232:
            url = '/minhaConta/alterarCliente'
            break;
        case 233:
            url = '/minhaConta/exibirCliente'
            break;
        case 240:
            url = '/minhaConta/meusDados'
            break;
        case 241:
            url = '/minhaConta/alterarMeusDados'
            break;        
        case 250:
            url = '/minhaConta/servicos'
            break;
        case 260:
            url = '/minhaConta/downloads'
            break;
        case 270:
            url = '/minhaConta/faleConosco'
            break;
        case 999:
            url = '/minhaConta/sair'
            break;
        default:
            url = '/'
            break;
    }
    return url
}
