import React, { useState } from 'react';

// fields
import { ButtonSendRequestCustom } from '../../../../components/Fields/button';
import { Text } from '../../../../components/Fields/text.js';

// services
import { AxiosChangePassword, CheckPasswordsInputs } from '../../../../business/ApiService.js';
import { validateForm } from '../../../../business/UserService.js';
import { formConfig } from '../../../../business/ConfigService.js';
import { Mask } from '../../../../business/MaskService.js';

// libs
import  { Typography, Box, makeStyles, Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//import Config from '../../../../utils/config.js';

const useStyles = makeStyles(theme => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    middleTitle2: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        opacity: '1',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    esqueceuSenhaLink: {
        width: '194px',
        height: '19px',        
        textAlign: 'center',
        font: 'normal normal 900 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#3D3078!important',
        textTransform: 'uppercase',
        opacity: '1',
        "&:hover": {
            textDecoration: 'underline!important',
        },
        // ['@media (max-width:980px)']: {
        //     width: '90%',
        // }
    },
    btnAzulEscuro: {
        width: '140px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#FFF',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    alertDefault: {
        backgroundColor: '#FFF',
        color: '#0D0048',
        font: 'normal normal medium 16px/19px Roboto',
        opacity: '1',
        letterSpacing: '0px',
        textAlign: 'left'
    }
}));

export function FormularioAlterarSenha(props) {
    /*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');
    const [passwordVerified, setPasswordVerified] = useState(true);

    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));      
    
    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: 'Senha alterada com sucesso.',
        loading: false,
        open: false,
        button: validateForm(conf)        
    });

    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {        
            version.textSnack = error;
            setAlertSeverity('error'); /* SNACKBAR */
        }

        setStage(version);
        setAlertMessage(version.textSnack);
        setOpen(true);
    };
    
    
    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
            version.loading = true;
            setStage(version)
        e.preventDefault();
        
        /* Usar sessão para forçar parâmetro */
        AxiosChangePassword('/auth/change-password', stage, version, callBack);
    };

    const buttonLabel = () => {
        return (
            <Typography variant="body2">
                <span className={ classes.middleTitle }>
                    <strong>Salvar</strong>
                </span>
            </Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        classes: classes.btnAzulEscuro
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };

    const verifyPassword = (field) => {
        let version = Object.assign({}, stage);
            version[field.id] = field;
        
        let status = CheckPasswordsInputs(version.newPassword.value, version.repeatPassword.value);        
        version.newPassword.error = status;
        version.repeatPassword.error = status;

        setStage(version);
        setPasswordVerified(status);
    };

    return (
        <form id={ props.idForm } onSubmit={ onFormSubmit } className="containerForm">
            <Box textAlign="left">
                <Text blur={ handleBlur } change={ handleChange } stage={ stage.oldPassword } />
            </Box>
            <Box textAlign="left">
                <Text blur={ verifyPassword } change={ handleChange } stage={ stage.newPassword } />
            </Box>
            <Box textAlign="left">
                <Text blur={ verifyPassword } change={ handleChange } stage={ stage.repeatPassword } />
            </Box>
            <Box textAlign="right">
                <Button className={ classes.btnBranco } href="#/minhaConta/meusDados">Cancelar</Button>
                <ButtonSendRequestCustom stage={ stage.button } disabled={ passwordVerified }>{ button }</ButtonSendRequestCustom>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
        </form>
    )
}
