import React, { useState, version } from 'react';
import Axios from 'axios';

// fields
import { Text } from '../../../../components/Fields/text'

// services
import { SV_API_CONTA } from '../../../../business/ExternalUrlService'

// libs
import { Typography, Box, withStyles, makeStyles, Grid, TextField, FormControl, InputBase } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

//import Config from '../../../../utils/config.js';


const useStyles = makeStyles(theme => ({
    middleTitle: {
    	display: 'block',
        color: '#fff',
        fontSize: '19px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D', 
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },    
    btnAzulEscuro: {        
        width: '114px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',        
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnAzulEscuroEditar: {        
        width: '158px',
        height: '50px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    imgBtnposicao: {
        height: '28px',
        width: '28px',
        left: '25px',
        position: 'absolute',
        top: '11px',
    },
    imgBtnEditCliente: {
        //height: '28px',
        //width: '28px',
        //position: 'absolute',
        //top: '21px',
        //left: '18px',
        marginTop: '15px',
        textAlign: 'center'
    },
    txtBtnposicao: {
        position: 'absolute',
        top: '16px',
        left: '65px',
        fontWeight: '400',
        lineHeight: '1.4',
        textAlign: 'center',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#fff',
        textTransform: 'uppercase',
    },
    checkbox: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #707070',        
        borderRadius: '360px',        
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 20,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: 'translateX(15px)',
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 1,
            color: '#7D7D7D',
            "&.Mui-checked": {
                transform: 'translateX(15px)',
                color: '#3D3078',
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
                },
            },
        },        
        "& .MuiSwitch-thumb": {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 20,            
            borderRadius: 16,
            transition: theme.transitions.create(['width'], { duration: 200, }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 0,            
            backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
            boxSizing: 'border-box',
            
        },
    },
    lineFormat: {
    	border: '1px solid #00000026',
		opacity: '1',
    },
    noEditableLabel: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#7B7B7B',
        width: '180px',
        height: '19px',
        marginBottom: '17px',
    },
    noEditableInfo: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        width: '370px',
        height: '19px',
        marginBottom: '17px',
    },
    groupTitle: {
        paddingTop: '30px',
        paddingBottom: '56px',
        width: '580px',
        height: '22px',
        textAlign: 'left',
        font: 'normal normal medium 18px/28px Roboto',
        letterSpacing: '0px',
        color: '#333333',
    },
    executiveTitle: {
        width: '223px',
        height: '29px',
        textAlign: 'left',
        font: 'normal normal bold 24px/29px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    executiveInfo: {
        marginBottom: '10px',
        width: '580px',
        height: '19px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    formTitle: {
        paddingTop: '22px',
        width: '181px',
        height: '28px',
        textAlign: 'left',
        font: 'normal normal bold 23px/28px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    infoAtendimento: {
        //top: '1531px',
        left: '431px',
        width: '442px!important',
        height: '47px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    btnAzulEscuroIconeApenas: {
        width: '50px',
        height: '50px',
        left: '5px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
}));

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #7D7D7D',
    fontSize: 16,
    textAlign: 'left',
    padding: '20px 26px 20px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto'
    ].join(','),
      '&:focus': {
        borderRadius: 2,
        borderColor: '#070026', //'#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
  },
}))(InputBase);

export function formataSaida(type, data)
{
    let nums = '';
    let increment = 0;
    let quantidadeNumeros = 0;

    if (type === 'cnpj'){ quantidadeNumeros = 15; }
    else if (type === 'cpf'){ quantidadeNumeros = 11; }
    else if (type === 'cep'){ quantidadeNumeros = 8; }
    else { quantidadeNumeros = 0 }

    // REALIZA A SUBTRAÇÃO PARA SABER QUANTOS ZEROS A ESQUEDA IMPLEMNETAR
    if (String(data).length < quantidadeNumeros)
    {
        increment = (quantidadeNumeros - String(data).length);
        for (let i=0; i < increment; i++) { nums += '0'; }
    }

    let format = `${nums}${data}`;
    
    if (type === 'cnpj'){ return format.replace(/(\d{3})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); }
    else if (type === 'cpf'){ return format.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); }
    else if (type === 'cep'){ return format.replace(/(\d{5})(\d{3})/, "$1-$2"); }
    else { return format; }
}

export function FormularioExibirCliente(props){
    const classes = useStyles();

    const [usuario, setUsuario] = useState({});
    const GetClient = () => {
        React.useEffect(() => {
            async function fetchData(){
                var idCliente = (window.location.hash.toString()).split("/")[3];
                var idPackage = (window.location.hash.toString()).split("/")[4];
                var instance = Axios.create({
                    baseURL: SV_API_CONTA,
                    timeout: 5000, 
                    headers: { 
                        "Accept": "*/*",
                        "Content-type": "application/json; charset=utf-8", 
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const r =  await instance.get(`/master/user-details?idUsuarioContaUser=${idCliente}&idPlano=${idPackage}`).then((response) => { return response.data.message }).catch((err) => { return err.response.data.message });
                setUsuario(r);
            }
            
            fetchData();

        }, []);

        var usuarioDetail = {};
        Object.entries(usuario).forEach(element => {
            switch (element[0])
            {
                case "dtNascimento":
                    usuarioDetail["dtNascimento"] = (element[1] !== "" && element[1] !== null) ? (new Date(element[1]).toLocaleString("pt-BR")).split(",")[0] : "Data não cadastrada";
                    break;
                case "email":
                    usuarioDetail["email"] = (element[1] !== "" && element[1] !== null) ? String(element[1]).toLowerCase() : "E-mail não informado";
                    break;
                case "emailRecuperacao":
                    usuarioDetail["emailRecuperacao"] = (element[1] !== "" && element[1] !== null) ? String(element[1]).toLowerCase() : "Não informado";
                    break;
                case "telefone":
                    usuarioDetail["telefone"] = (element[1] !== "" && element[1] !== null) ? `(${element[1].substr(0, 2)}) ${element[1].substr(2)}` : "Não informado";
                    break;
                case "celular":
                    usuarioDetail["celular"] = (element[1] !== "" && element[1] !== null) ? `(${element[1].substr(0, 2)}) ${element[1].substr(2)}` : "Não informado";
                    break;
                case "cpf":
                    usuarioDetail["cpf"] = (element[1] !== "" && element[1] !== null && element[1] !== 0) ? formataSaida(element[0], element[1]) : "Não informado";
                case "cnpj":
                    usuarioDetail["cnpj"] = (element[1] !== "" && element[1] !== null && element[1] !== 0) ? formataSaida(element[0], element[1]) : "Não informado";
                    break;
                case "cep":
                    usuarioDetail["cep"] = (element[1] !== "" && element[1] !== null) ? formataSaida(element[0], element[1]) : "Não informado";
                    break;                
                default:
                    usuarioDetail[ element[0] ] = (element[1] !== "" && element[1] !== null) ? String(element[1]).toUpperCase() : "-";
            }
        });

        return (
            <>
                <Box textAlign="left">
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Plano:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["nomePacote"] }</Box>
                        </Grid>
                    </Grid>
                </Box>
                <br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                <Box className={ classes.groupTitle }>Dados do usuário</Box>
                <Box textAlign="left">
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Nome:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["nome"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Cargo:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["cargo"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Departamento:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["departamento"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Data nascimento:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["dtNascimento"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={classes.noEditableLabel}>CPF:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={classes.noEditableInfo}>{ usuarioDetail["cpf"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>E-mail:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["email"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>E-mail de recuperação:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["emailRecuperacao"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Telefone:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["telefone"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Celular:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["celular"] }</Box>
                        </Grid>
                    </Grid>
                </Box>
                <br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                <Box className={ classes.groupTitle }>Endereço</Box>
                <Box textAlign="left">
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>CEP:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["cep"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Logradouro:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["logradouro"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Número:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["numero"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Complemento:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["complemento"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Bairro:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["bairro"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Cidade:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["cidade"] }</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Estado:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>{ usuarioDetail["estado"] }</Box>
                        </Grid>
                    </Grid>
                </Box>
                <br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr><br/>
                <Box>
                    <Box>
                        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                            <Grid item>
                                <img src="/img/icons/ico_info.svg" alt="icon_info"/>
                            </Grid>
                            <Grid item md={10} className={ classes.infoAtendimento }>
                                Para editar essas informações, favor entrar em contato com nosso atendimento.
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <form id={ props.idForm } className="containerForm3" style={{ minHeight: "1280px" }}>
        	<Box className={ classes.titleSpacing }>
        		<Box textAlign="left" className={ classes.titleSpacing }>
                    <Typography className={ classes.formTitle }>Dados cadastrais</Typography>
                </Box>
                <br/>
                <Box sx={{ height: "650px", width: "100%" }}>{ GetClient() }</Box>
			</Box>
        </form>
    )
}