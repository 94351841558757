import React, { useState, useEffect } from 'react';
import Axios from 'axios';

// services
import { SV_API_CONTA } from '../../../business/ExternalUrlService';

// libs
import  { Box, makeStyles, Grid } from '@material-ui/core';

//import Config from '../../../utils/config.js';

const useStyles = makeStyles(theme => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    middleTitle2: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        opacity: '1',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    esqueceuSenhaLink: {
        width: '194px',
        height: '19px',        
        textAlign: 'center',
        font: 'normal normal 900 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#3D3078!important',
        textTransform: 'uppercase',
        opacity: '1',
        "&:hover": {
            textDecoration: 'underline!important',
        },
        // ['@media (max-width:980px)']: {
        //     width: '90%',
        // }
    },
    btnAzulEscuro: {
        width: '140px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#FFF',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    alertDefault: {
        backgroundColor: '#FFF',
        color: '#0D0048',
        font: 'normal normal medium 16px/19px Roboto',
        opacity: '1',
        letterSpacing: '0px',
        textAlign: 'left'
    },
    lineFormat: {
        border: '1px solid #00000026',
    },
    noEditableLabel: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#7B7B7B',
        width: '100px',
        height: '19px',
        marginBottom: '17px',
    },
    noEditableInfo: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        //width: '370px',
        height: '19px',
        marginBottom: '17px',
    },
    executiveTitle: {
        marginBottom: '31px',
        height: '29px',
        textAlign: 'left',
        font: 'normal normal bold 24px/29px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    executiveInfo: {
        marginBottom: '10px',
        width: '580px',
        height: '19px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
}));

export function FormularioListarPacotes(props) {
    
    const classes = useStyles();
    //const conf = Object.assign({}, formConfig(props.idForm));

    /* dict com número dos executivos de vendas - provisório */
    const executivoVendas = [
        { 'kleber.bergamasco@valor.com.br': '(11) 97156-2183' },
        { 'andre.spinelli@valor.com.br': '(11) 97457-8716' },
        { 'antonio.lacerda@valor.com.br': '(11) 96399-9438' },
        { 'elisson.freitas@valor.com.br': '(11) 97565-8078' },
        { 'wesley.milanos@valor.com.br': '(21) 98245-9904' },
        { 'bruno.paula@valor.com.br': '(61) 99838-5710' }
    ];
    
    const [contrato, setContrato] = useState(null);
    const GetContracts = () => {
        useEffect(() => {
            async function fetchData(){

                var idUsuarioConta = parseInt(sessionStorage.getItem('idUsuarioConta'));
                var instance = Axios.create({
                    baseURL: SV_API_CONTA,
                    timeout: 5000, 
                    headers: { 
                        "Accept": "*/*",
                        "Content-type": "application/json; charset=utf-8", 
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const r =  await instance.get(`/master/services-summary/?idUsuarioConta=${idUsuarioConta}`).then((response) => { return response.data.message }).catch((err) => { return err.response.data.message });
                (r !== null && typeof r !== "undefined") ? setContrato(r) : setContrato(null);
            }

            fetchData();

        }, []);

        var dadosContratuais = [];
        
        if (contrato !== null)
        {
            let cDate = (contrato['startDate'].split("T")[0]).split("-");
                cDate = `${cDate[2]}/${cDate[1]}/${cDate[0]}`;

            let cExecutivoEmail = (contrato['salesExecutiveEmail']).toLowerCase();

            dadosContratuais.push(
                <Box>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item className={ classes.noEditableLabel }>Data início:</Grid>
                        <Grid item className={ classes.noEditableInfo }>{ cDate }</Grid>
                    </Grid>
                    <br/>
                    <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                </Box>
            );

            if (contrato['servicesSummary'].length > 0)
            {
                for(let i=0; i < contrato['servicesSummary'].length; i++)
                {
                    dadosContratuais.push(
                        <Box>
                            <br/>
                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item className={ classes.noEditableLabel }>Pacote:</Grid>
                                <Grid item className={ classes.noEditableInfo }>{ contrato['servicesSummary'][i]['package'] }</Grid>
                            </Grid>
                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item className={ classes.noEditableLabel }>Usuários:</Grid>
                                <Grid item className={ classes.noEditableInfo }>{ contrato['servicesSummary'][i]['numberUsers'] } (Adicione ou exclua usuários em "Usuários")</Grid>
                            </Grid>
                            <br/>
                            <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>                            
                        </Box>
                    );
                }
            }

            dadosContratuais.push(
                <Box>
                    <br/>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" className={ classes.titleSpacing }>
                        <Grid item className={ classes.executiveTitle }>Executivo de vendas</Grid>
                    </Grid>                 
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item className={ classes.executiveInfo }>{ contrato['salesExecutiveName'] }</Grid>                        
                        <Grid item className={ classes.executiveInfo }>{ cExecutivoEmail }</Grid>
                        {/* <Grid item className={ classes.executiveInfo }>{ contrato['salesExecutiveNumber'] }</Grid> */}
                        { 
                            executivoVendas.map((x) => { 
                                return (typeof x[cExecutivoEmail] !== "undefined") ? <Grid item className={ classes.executiveInfo }>{ x[cExecutivoEmail] }</Grid> : '';
                            })
                        }                        
                    </Grid>
                </Box>
            );
        }
        else
        {
            dadosContratuais.push(
                <Box>
                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                        <Grid item>
                            <img src="/img/icons/ico_info.svg" alt="icon_info"/>
                        </Grid>
                        <Grid item md={10} className={ classes.noEditableInfo }>
                            Dados do contrato disponíveis somente com cliente(s) cadastrado(s).
                        </Grid>
                    </Grid>
                    <br/>
                    <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                </Box>
            );
        }

        return dadosContratuais;
    }

    return (
        <form id={ props.idForm } className="containerForm3">
            <Box textAlign="left">{ GetContracts() }</Box>
        </form>
    )
}
