import React, { Fragment } from 'react';
import { Header } from '../../components/Header/app.js'
import { Footer } from '../../components/Footer/app.js'
import { Box, Typography, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        color: '#7d7d7d',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    colorTitle: {
        color: '#7d7d7d!important'
    }
}));

export function PageError404() {
    const classes = useStyles();

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);
            //console.log(blocoSection);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 500);

    return (
        <Fragment>
            <Header />

            <section id="section" className={classes.root}>
                <Box textAlign="center" className={classes.container}>
                    <Typography variant="h1" className={classes.colorTitle}>
                        Página não encontrada
                    </Typography>
                </Box>
            </section>

            <Footer />
        </Fragment>
    )
}