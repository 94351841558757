import React from 'react'
import clsx from 'clsx'
import { Box, Typography, makeStyles, Button, SwipeableDrawer } from '@material-ui/core'
import { HashLink as Link } from 'react-router-hash-link'
//import SubMenuPacotes from './subMenuPacotes.js'

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    wrapperLinks: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    containerMenu: {
        backgroundColor: '#0D0048',
        height: '100%',
        paddingTop: '15px',
    },
    menuLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '271px',
        height: '40px',
        textAlign: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    bgLinks: {
        backgroundColor: '#0F0051'
    },
    btnOpen: {
        display: 'none',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'url(./img/icon_menu.png) no-repeat center center',
        minWidth: '40px',
        height: '14px',
        margin: '17px 0 0',
        padding: '0',
        ['@media (max-width:880px)']: { // eslint-disable-line no-useless-computed-key
            display: 'block',
        },
    },
    btnClose: {
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: '1.1rem',
        padding: '3px 0 0',
        minWidth: '39px',
        minHeight: '40px',
        borderRadius: '0',
        boxShadow: 'none',
    },
    containerButton: {
        backgroundColor: '#030013',
        width: '100%',
        height: '41px',
        margin: '0 0 5px',
    },

});

export function MenuMasterMobile() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    let list = (anchor) => (
        <nav className={classes.nav}>
            <Typography variant="h5" className={ classes.root }>
                <Box className={ classes.wrapperLinks }>
                    <Link to="/minhaConta/clientes" className={ classes.menuLinks }>Usuários</Link>
                    <Link to="/minhaConta/meusDados" className={ classes.menuLinks }>Dados</Link>
                    <Link to="/minhaConta/servicos" className={ classes.menuLinks }>Contrato</Link>
                    <Link to="/minhaConta/downloads" className={ classes.menuLinks }>Downloads</Link>
                    <Link to="/minhaConta/faleConosco" className={ classes.menuLinks }>Fale Conosco</Link>
                    <Link to="/minhaConta/sair" className={ classes.menuLinks } >Sair</Link>
                </Box>
            </Typography>
        </nav>
    );
    
    return (        
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={ anchor }>
                    <Button className={ classes.btnOpen } onClick={ toggleDrawer(anchor, true) } />
                    <SwipeableDrawer anchor={ anchor } open={ state[anchor] } onClose={ toggleDrawer(anchor, false) } onOpen={ toggleDrawer(anchor, true) }>
                        <Box className={ classes.containerButton }>
                            <Button className={ classes.btnClose } onClick={ toggleDrawer(anchor, false) }>X</Button>
                        </Box>
                        <Box className={ classes.containerMenu }>
                            { list(anchor) }
                        </Box>
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export function MenuUsuarioMobile() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    let list = (anchor) => (
        <nav className={ classes.nav }>
            <Typography variant="h5" className={ classes.root }>
                <Box className={ classes.wrapperLinks }>
                    <Link to="/minhaConta/meusDados" className={ classes.menuLinks }>Dados</Link>
                    <Link to="/minhaConta/servicos" className={ classes.menuLinks }>Contrato</Link>
                    <Link to="/minhaConta/downloads" className={ classes.menuLinks }>Downloads</Link>
                    <Link to="/minhaConta/faleConosco" className={ classes.menuLinks }>Fale Conosco</Link>
                    <Link to="/minhaConta/sair" className={ classes.menuLinks }>Sair</Link>
                </Box>
            </Typography>
        </nav>
    );
    
    return (        
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={ anchor }>
                    <Button className={ classes.btnOpen } onClick={ toggleDrawer(anchor, true) } />
                    <SwipeableDrawer anchor={ anchor } open={ state[anchor] } onClose={ toggleDrawer(anchor, false) } onOpen={ toggleDrawer(anchor, true) }>
                        <Box className={ classes.containerButton }>
                            <Button className={ classes.btnClose } onClick={ toggleDrawer(anchor, false) }>X</Button>
                        </Box>
                        <Box className={ classes.containerMenu }>
                            { list(anchor) }
                        </Box>
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}