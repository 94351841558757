import React from 'react';
import { setUnknown } from '../../business/UserService'
import { FormHelperText, InputBase, Select, FormControl, MenuItem, InputLabel, withStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: '33px',
    },
    label: {
        'background-color': '#ffffff',
        padding: '0 5px 0 5px',
    },
}));
const BootstrapInput = withStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        'text-align': 'left',
        padding: '20px 26px 20px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Roboto'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


export function SelectFields(props) {
    const classes = useStyles();
    const handleChange = event => {
        const version = Object.assign({}, props.stage)
        let result = setUnknown(version, event.target.value)
        version['value'] = event.target.value
        props.stage.value = event.target.value
        version['error'] = false
        version['helperText'] = ''
        if (result.error) {
            version['error'] = true
            version['helperText'] = result.error
        }
        props.blur(version);
    };
    return (
        <FormControl variant="outlined" className={classes.root} error={props.stage.error}>
            <InputLabel id={props.stage.id + "-label"} required={props.stage.required} className={classes.label}>{props.stage.label}</InputLabel>
            <Select
                labelId={props.stage.id + "-label"}
                id={props.stage.id}
                onChange={handleChange}
                input={<BootstrapInput />}
                value={props.stage.value}
                required={props.stage.required}
                disabled={props.stage.disabled}
            >
                {
                    props.dados.map(
                        row => <MenuItem key={row.key} value={row.value}>{row.value}</MenuItem>
                    )
                }
            </Select>
            <FormHelperText>{props.stage.helperText}</FormHelperText>
        </FormControl>
    );
}
