import React, { Fragment } from 'react';
import { Header } from '../../components/Header/app.js'
import { Footer } from '../../components/Footer/app.js'
import { Box, makeStyles, Typography } from '@material-ui/core';

import CarouselLanding from './carouselLanding.js';
import ContentLanding from './carouselLanding.js';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: 'rgb(1, 4, 17)',
        background: 'linear-gradient(270deg, rgba(1,4,17,1) 5%, rgba(38,43,72,1) 100%)', // eslint-disable-line no-useless-computed-key
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: '#0D0048',
            background: 'transparent',
        }
    },
    container: {
        width: '1196px',
        margin: 'auto',
        padding: '55px 0 55px',
        ['@media (max-width:1212px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 170px)',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 74px)',
            padding: '0 0 55px',
        }
    },
    blcSocial: {
        display: "flex",
        justifyContent: "center",
        margin: '20px 0 0',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 74px)',
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            margin: '40px 0 0',
            height: "130px",
            '& > * + *': {
                margin: theme.spacing(0),
            },
        },
    },
    boxSocial: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoSocial: {
        display: 'block',
        maxWidth: '100%',
        height: '17px',
        marginRight: '10px',
    },
    boxTitle: {
        margin: '50px 0',
        textAlign: 'center',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            margin: '35px 0 20px',
        }
    },
    boxTitle2: {
        margin: '116.67px 236px 150px',
        width: '792px',
        textAlign: 'center',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            margin: '86px 0 20px',
            width: '100%',
        }
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '40px 0',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: 'center',
            padding: '20px 0',
        }
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        }
    },
    txtRight: {
        width: '500px',
        marginRight: '50px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            marginRight: '0',
            textAlign: 'center',
        }
    },
    txtLeft: {
        width: '500px',
        marginLeft: '50px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            marginLeft: '0',
            textAlign: 'center',
        }
    },    
    boxCarousel: {
        left: '85px',
        width: '1196.6px',
        height: '616px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '1px 16px 24px #00000029',
        border: '1px solid #707070',
        borderRadius: '12px',
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',            
            height: '906px',
        }
    },
    carouselMargin: {
        position: 'relative',
        margin: '58px 30px',
        width: '1136px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            margin: '8px 15px',
            width: '300px',            
        }
    },
    box1: {
        padding: '38px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            padding: '18px 0',
            maxWidth: '100%',
        }
    },
    box2: {
        //paddingBottom: "21px",
        padding: '40px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            padding: '30px 0',
            maxWidth: '100%',
        }
    },
    boxPadding1: {
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            padding: '18px 0',
            maxWidth: '100%',
        }
    },
    imgDestaqueMobile: {
        display: 'none',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key               
            display: 'block',
            padding: '40px 0',
            opacity: '1',
            margin: '0 auto',
        },
    },
    imgDestaqueDireita1: {
        paddingTop: '25px',
        paddingRight: '0px',        
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key               
            display: 'none',
        },
    },
    imgDestaqueEsquerda1: {
        paddingTop: '25px',
        paddingLeft: '0px',
        opacity: '1',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key               
            display: 'none',
        },
    },
    depoiments: {
        position: 'relative',
        top: '58px',
        bottom: '58px',
        left: '116px',
        right: '116px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            top: '28px',
            bottom: '28px',
            left: '0px',
            right: '0px',
            textAlign: 'center',
        }
    }
}));

export function QuemSomos() {
    const classes = useStyles();
    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box className={classes.container}>
                    <Box className={classes.boxPadding1}>
                        <Box className={classes.boxTitle}>
                            <Typography variant="h1">Quem somos</Typography>
                        </Box>
                        <img width="100%" src="./img/img_09.png" alt="" className={classes.imgDestaqueMobile}/>
                        <Box className={classes.box}>                            
                            <Box className={classes.txtRight}>
                                <Typography>
                                    A <strong>Editora Globo</strong> é a ramificação do Grupo Globo responsável pela publicação das revistas Época, Galileu, Auto Esporte, Casa e Comida, Casa e Jardim, Globo Rural, Pequenas Empresas & Grandes Negócios, Quem e numa parceria com a Condé Nast, publica as revistas Vogue e GQ no Brasil.<br /><br />
                                    O <strong>Valor Econômico</strong> está no mercado desde 2000 e conquistou a credibilidade e confiança dos principais executivos e formadores de opinião do país. No final de 2012, lançou o Valor PRO, um serviço de informações em tempo real que oferece cotações, ferramentas exclusivas para análises e notícias em primeira mão para auxiliar a tomada de decisões.<br /><br />
                                    Juntos, desde janeiro de 2018, <strong>Editora Globo</strong> e <strong>Valor Econômico</strong> deram origem a uma organização ainda mais forte e relevante dos pontos de vista editorial, comercial e institucional, tornando-se a maior empresa brasileira de publicações em plataformas impressa e digital.                                
                                </Typography>
                            </Box>
                            <Box className={classes.img}>
                                <img width="100%" src="./img/img_09.png" alt="" className={classes.imgDestaqueDireita1}/>
                            </Box>
                        </Box>
                    </Box>

                    <Box class={classes.box1}></Box>

                    <Box id="boxCarousel" className={classes.boxCarousel}>
                        <Typography variant="h1" className={classes.depoiments}>
                            <strong>Depoimentos</strong>
                        </Typography>
                        <Box className={classes.carouselMargin}>
                            <CarouselLanding>
                                <ContentLanding />
                            </CarouselLanding> 
                        </Box>
                    </Box>

                    <Box class={classes.box2}></Box>

                    <Box id="conteudo"></Box>
                    <Box className={classes.boxTitle}>
                        <Typography variant="h1">
                            <strong>Conheça nossos outros produtos</strong>
                        </Typography>
                        <Typography>Um conjunto de ferramentas para facilitar o seu dia a dia.</Typography>                        
                    </Box>
                    
                    <img width="100%" src="./img/img_10.png" alt="" className={classes.imgDestaqueMobile}/>
                    <Box className={classes.box}>
                        <Box className={classes.img}>
                            <img width="100%" src="./img/img_10.png" alt="" className={classes.imgDestaqueEsquerda1}/>
                        </Box>
                        <Box className={classes.txtLeft}>
                            <Typography variant="h1">
                                <strong>Valor Conteúdo</strong>
                            </Typography>
                            <Typography>
                                Informações precisas e de qualidade para seu website. Envio de cotações sob demanda em delay, do mercado nacional de renda variável, fixa, cambio ou commodites, realizado por uma API WEB em um formato JSON. O Valor Conteúdo conta com o Valor +News, que traz textos produzidos por uma equipe de reportagem altamente especializada e conta com correspondentes em pontos estratégicos no Brasil e no Mundo. São cerca de 40 notícias por dia que cobrem os seguintes temas: Política, Finanças, Brasil, Empresas e Internacional. Você também terá acesso ao Valor International, que é um produto exclusivo, em inglês, com uma seleção de notícias e análises sobre o Brasil (Empresas e Governo), relevante para o mercado internacional.
                            </Typography>
                        </Box>
                        <Box id="clipping"></Box>
                    </Box>
                    
                    
                    <img width="100%" src="./img/img_11.png" alt="" className={classes.imgDestaqueMobile}/>
                    <Box className={classes.box}>                        
                        <Box className={classes.txtRight}>
                            <Typography variant="h1">
                                <strong>Valor Clipping</strong>
                            </Typography>
                            <Typography>
                                É o nosso sistema de envio eletrônico de notícias selecionadas conforme interesse. O Valor Clipping é uma forma prática e ágil de sua equipe acessar as notícias e assuntos mais relevantes, que influenciam o dia a dia de sua empresa. São as notícias da versão impressa e on-line do Jornal Valor Econômico, filtradas por palavras-chave, temas ou editorias.
                            </Typography>
                        </Box>
                        <Box className={classes.img}>
                            <img width="100%" src="./img/img_11.png" alt="" className={classes.imgDestaqueDireita1}/>
                        </Box>
                    </Box>

                    <Box className={classes.boxTitle2}>
                        <Typography variant="h1">
                            <strong>Ficou interessado?</strong>
                        </Typography>
                        <Typography>
                            Para saber mais sobre o <strong>Valor Conteúdo</strong> e o <strong>Valor Clipping</strong>, entre em contato com a Central de Atendimento do <strong>Valor PRO</strong>, disponível de segunda a sexta-feira pelo telefone 0800-003-1232 e no e-mail <a href="mailto:falecom@valor.com.br">falecom@valor.com.br</a>
                        </Typography>
                        {/*
                        <nav>
                            <Box className={classes.blcSocial}>
                                <Link href="https://www.youtube.com/channel/UC8hgDiwrkJRw_u3MLv1gGRw" target="_blank" className={classes.boxSocial}>
                                    <Typography variant="h5" className={classes.linkSocialYoutube}>Youtube</Typography>
                                </Link>
                                <Link href="https://www.instagram.com/valorpro/" target="_blank" className={classes.boxSocial}>
                                    <Typography variant="h5" className={classes.linkSocialInstagram}>Instagram</Typography>
                                </Link>
                                <Link href="https://www.facebook.com/Valor-PRO-103220501226811" target="_blank" className={classes.boxSocial}>
                                    <Typography variant="h5" className={classes.linkSocialFacebook}>Facebook</Typography>
                                </Link>
                            </Box>
                        </nav>
                        */}
                    </Box>
                </Box>
            </section>
            <Footer />
        </Fragment>
    )
}