import React, { Fragment } from 'react';

import { Header } from '../../components/Header/app.js'
import { Footer } from '../../components/Footer/app.js'
import { Box, makeStyles } from '@material-ui/core'

import { FormLogin } from './form'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#07002633', //'#0d0048',
        color: '#fff',
        paddingTop: '80px',
        paddingBottom: '100px',
    },
    container: {
        padding: '55px 0',
        //height: '318px',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 24px)',
            margin: 'auto',
            padding: '20px 0',
        },
        ['@media (max-width:320px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        },
    },
    textBox: {
        textAlign: 'center',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 50px)',
            margin: 'auto',
        },
    }
}));

export function MinhaConta() {
    
    const classes = useStyles();
    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);
            //console.log(blocoSection);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 200);

    return (
        <Fragment>
            <Header />
            <section id="section" className={ classes.root }>
                <Box textAlign="center" className={ classes.container }>
                    <Box className="container formPadding">
                        <FormLogin idForm="formularioLogin"/><Fragment />
                    </Box>
                </Box>
            </section>
            <Footer />
        </Fragment>
    )
}