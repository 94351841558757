import React, { Fragment, useState, version } from 'react';

// fields
import { Text } from '../../components/Fields/text'
import { CheckField } from '../../components/Fields/check'
import { ButtonSendRequestCustom } from '../../components/Fields/button'

// services
import { AxiosSubmitLogin } from '../../business/ApiService'
import { validateForm } from '../../business/UserService'
import { formConfig } from '../../business/ConfigService'
import { Mask } from '../../business/MaskService'

// libs
import  { Typography, Box, makeStyles, Button, Link, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Config from '../../utils/config.js';

const useStyles = makeStyles(theme => ({
    middleTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    esqueceuSenhaLink: {
        width: '194px',
        height: '19px',        
        textAlign: 'center',
        font: 'normal normal 900 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#3D3078!important',
        textTransform: 'uppercase',
        opacity: '1',
        "&:hover": {
            textDecoration: 'underline!important',
        },
        ['@media (max-width:980px)']: {
            width: '90%',
        }
    },
    btnAzulEscuro: {
        width: '140px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#FFF',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    }
}));

export function FormLogin(props) {
    /*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));
    
    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: 'Bem-vindo(a)!',
        button: validateForm(conf)
    });
    
    // Transformar o objeto em string e salvar em localStorage
    if (localStorage.length > 0)
    {
        conf.username.value = JSON.parse(localStorage.getItem('username'));
        conf.password.value = JSON.parse(localStorage.getItem('password'));
        conf.saveCookie.value = true;
    }
    
    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {        
            version.textSnack = error;
            setAlertSeverity('error'); /* SNACKBAR */
        }

        setStage(version);
        setAlertMessage(version.textSnack);
        setOpen(true);
    };    
       
    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
        version.loading = true;
        setStage(version)
        e.preventDefault();

        if (version.saveCookie.value)
        {
            // Transformar o objeto em string e salvar em localStorage
            localStorage.setItem('username', JSON.stringify(stage.username.value));
            localStorage.setItem('password', JSON.stringify(stage.password.value));
        }
        else
        {
            localStorage.clear();
            conf.saveCookie.value = false
        }

        /* nova conexao utilizando axios */
        await AxiosSubmitLogin('/auth/login', stage, version, callBack);
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };

    const buttonLabel = () => {
        return (
            <Typography variant="body2">
                <span className={ classes.middleTitle }>
                    <strong>Entrar</strong>
                </span>
            </Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        classes: classes.btnAzulEscuro
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);
        
        window.location.reload();
    };
    
    return (
        <form id={ props.idForm } onSubmit={ onFormSubmit } className="containerForm">
            <Box textAlign="left" className={ classes.titleSpacing }>
                <Typography variant="subtitle1" className={ classes.titleColor }>
                    <strong>Entrar</strong>
                </Typography>
            </Box>
            <Box textAlign="left">
                <Text blur={ handleBlur } change={ handleChange } stage={ stage.username }/>
            </Box>
            <Box textAlign="left">
                <Text blur={ handleBlur } change={ handleChange } stage={ stage.password }/>
            </Box>
            <Box textAlign="left" className={ classes.checkBox } >
                <CheckField blur={ handleBlur } stage={ stage.saveCookie } />
            </Box>
            <Box>
                <Button className={ classes.btnBranco } href="#">Cancelar</Button>
                <ButtonSendRequestCustom stage={ stage.button }>{ button }</ButtonSendRequestCustom>
            </Box>
            <br/>
            <Box>
                <Link className={ classes.esqueceuSenhaLink } href="#/solicitarSenha">Esqueceu sua senha</Link>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
        </form>
    )
}