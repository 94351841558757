import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    button: {
        margin: '7px 0 15px',
        width: '218px',
        minHeight: '49px',
        'opacity': '1',
        font: 'Bold 16px/24px Roboto',
        'letter-spacing': '0',
        '&:hover': {
            boxShadow: '0px 3px 6px #00000035',
        },
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            minHeight: '50px',
        },
    },
    contained: {
        background: '#3D3078 0% 0% no-repeat padding-box;',
        boxShadow: '0px 3px 6px #00000029',
        color: '#fff',
        '&$disabled': {
            cursor: 'not-allowed',
            backgroundColor: '#0000001F',
            color: '#7A7A7A'
        },
        '&:hover': {
            color: '#fff',
            boxShadow: '0px 3px 6px #00000035',
            backgroundColor: '#3D3078',
        }
    },
    disabled: {
        cursor: 'not-allowed',
        backgroundColor: '#0000001F',
        color: '#7A7A7A'
    },
    buttonCheck: {
        border: '1px solid #3D3078',
        color: '#3D3078',
        '&:hover': {
            color: '#3D3078',
            boxShadow: '0px 0px 6px #3D3078',
            border: '1px solid #3D3078',
            backgroundColor: '#fff!important'
        }
    },
    buttonDynamicSize: {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingRight: '28px',
        paddingLeft: '28px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    }
}));


export function ButtonClose(props) {
    const classes = useStyles();
    let config = props.children
    return (
        <Button
            className={clsx(classes.button, classes.contained, config.class)}
            variant='contained'
            disabled={props.disabled}
            href={config.href}
        >
            {config.title}
        </Button>
    )
}

export function ButtonCheck(props) {

    const classes = useStyles();
    let config = props.children

    return (
        <ButtonClick
            class={clsx(classes.button, classes[config.class])}
            config={config}
            {...props}
        />
    )
}

export function ButtonFinish(props) {
    let config = {
        title: 'FINALIZAR',
        class: 'button',
        variant: 'contained'
    }
    return (<ButtonProgress config={config} {...props} />)
}


export function ButtonFields(props) {
    let config = {
        title: 'Continuar',
        class: 'button',
        variant: 'contained'
    }
    return (<ButtonProgress config={config} {...props} />)
}

export function ButtonSendRequest(props) {
    let config = props.children
    return (<ButtonProgress config={config} {...props} />)
}

export function ButtonSendRequestCustom(props) {
    let config = props.children
    return (<ButtonProgressCustom config={config} {...props} />)
}

function ButtonClick(props) {
    return (
        <Button
            className={props.class}
            variant={props.config.variant}
            disabled={props.disabled}
            onClick={() => props.click()}
        >{props.config.title}</Button>
    )
}

function ButtonProgress(props) {
    const classes = useStyles();
    return (
        <Button
            className={clsx(classes.button, classes.contained, props.config.class)}
            variant={props.config.variant}
            disabled={props.disabled}
            type="submit"
        >{props.config.title}</Button>
    )
}


function ButtonProgressCustom(props) {
    const classes = useStyles();
    return (
        <Button
            className={clsx(classes.buttonDynamicSize, classes.contained, props.config.class)}
            variant={props.config.variant}
            disabled={props.disabled}
            type="submit"
        >{props.config.title}</Button>
    )
}