import React, { Fragment } from 'react';
import { Header } from '../../components/Header/app.js'
import { HeaderMinhaConta } from '../MinhaConta/Menu/app.js'
import { Footer } from '../../components/Footer/app.js'
import { Box, Typography, makeStyles, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        color: '#7d7d7d',
    },
    container: {
        width: '800px',
        margin: 'auto',
        padding: '55px 0',
        textAlign: 'center',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 74px)',
            padding: '20px 0',
        },
    },
    blcSocial: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "130px",
            '& > * + *': {
                margin: theme.spacing(0),
            },
        },
    },
    boxSocial: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoSocial: {
        display: 'block',
        maxWidth: '100%',
        height: '17px',
        marginRight: '10px',
    },
    linkSocialYoutube: {
        background: 'url(./img/icon_youtube_cinza.png) no-repeat left 0',
        width: '55px',
        height: '20px',
        padding: '0 0 0 31px',
        color: '#7d7d7d',
        '&:hover': {
            background: 'url(./img/icon_youtube_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
    linkSocialInstagram: {
        background: 'url(./img/icon_instagram_cinza.png) no-repeat left 0',
        width: '66px',
        height: '20px',
        padding: '0 0 0 28px',
        color: '#7d7d7d',
        '&:hover': {
            background: 'url(./img/icon_instagram_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
    linkSocialFacebook: {
        background: 'url(./img/icon_facebook_cinza.png) no-repeat left 0',
        width: '64px',
        height: '20px',
        padding: '0 0 0 16px',
        color: '#7d7d7d',
        '&:hover': {
            background: 'url(./img/icon_facebook_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
}));

export function FaleConosco() {
    const classes = useStyles();
    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box className={classes.container}>
                    <Typography variant="h1">
                        Fale Conosco
                    </Typography>

                    <Typography variant="body1">
                        A Central de Atendimento do <strong>Valor PRO</strong> está disponível de segunda a sexta-feira pelo telefone 0800-003-1232 e pelo e-mail falecom@valor.com.br
                    </Typography>
                    <br />
                    <nav>
                        <Box className={classes.blcSocial}>
                            <Link href="https://www.youtube.com/channel/UC8hgDiwrkJRw_u3MLv1gGRw" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialYoutube}>Youtube</Typography>
                            </Link>
                            <Link href="https://www.instagram.com/valorpro/" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialInstagram}>Instagram</Typography>
                            </Link>
                            <Link href="https://www.facebook.com/Valor-PRO-103220501226811" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialFacebook}>Facebook</Typography>
                            </Link>
                        </Box>
                    </nav>
                    <br />
                    <Typography>
                        <strong>Endereços e telefones:</strong>
                    </Typography>
                    <br />
                    <Typography>
                        <strong>São Paulo</strong><br />
                            Avenida 9 de Julho, 5229 | 8º andar<br />
                            CEP: 01407-907 | São Paulo | SP<br />
                            Telefone: 11 3767-1000
                    </Typography>
                    <br />
                    <Typography>
                        <strong>Rio de Janeiro</strong><br />
                            Rua Marquês de Pombal, 25 | 2º<br />
                            CEP: 20230-240 | Rio de Janeiro | RJ<br />
                            Telefone: 21 3521-1400
                    </Typography>
                    <br />
                    <Typography>
                        <strong>Brasília</strong><br />
                            Setor Comercial Norte Quadra 5<br/>
                            Torre Sul | Sala 301 | Brasília Shopping<br/>
                            CEP: 70715-900 | Brasília | DF<br/>
                            Telefone: 61 3717-3300
                    </Typography>
                </Box>
            </section>

            <Footer />
        </Fragment>
    )
}

export function FaleConoscoLogado() {
    const classes = useStyles();
    return (
        <Fragment>
            <HeaderMinhaConta />
            <section id="section" className={classes.root}>
                <Box className={classes.container}>
                    <Typography variant="h1">
                        Fale Conosco
                    </Typography>

                    <Typography variant="body1">
                        A Central de Atendimento do <strong>Valor PRO</strong> está disponível de segunda a sexta-feira pelo telefone 0800-003-1232 e pelo e-mail falecom@valor.com.br
                    </Typography>
                    <br />
                    <nav>
                        <Box className={classes.blcSocial}>
                            <Link href="https://www.youtube.com/channel/UC8hgDiwrkJRw_u3MLv1gGRw" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialYoutube}>Youtube</Typography>
                            </Link>
                            <Link href="https://www.instagram.com/valorpro/" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialInstagram}>Instagram</Typography>
                            </Link>
                            <Link href="https://www.facebook.com/Valor-PRO-103220501226811" target="_blank" className={classes.boxSocial}>
                                <Typography variant="h5" className={classes.linkSocialFacebook}>Facebook</Typography>
                            </Link>
                        </Box>
                    </nav>
                    <br />
                    <Typography>
                        <strong>Endereços e telefones:</strong>
                    </Typography>
                    <br />
                    <Typography>
                        <strong>São Paulo</strong><br />
                            Avenida 9 de Julho, 5229 | 8º andar<br />
                            CEP: 01407-907 | São Paulo | SP<br />
                            Telefone: 11 3767-1000
                    </Typography>
                    <br />
                    <Typography>
                        <strong>Rio de Janeiro</strong><br />
                            Rua Marquês de Pombal, 25 | 2º<br />
                            CEP: 20230-240 | Rio de Janeiro | RJ<br />
                            Telefone: 21 3521-1400
                    </Typography>
                    <br />
                    <Typography>
                        <strong>Brasília</strong><br />
                            Setor Comercial Norte Quadra 5<br/>
                            Torre Sul | Sala 301 | Brasília Shopping<br/>
                            CEP: 70715-900 | Brasília | DF<br/>
                            Telefone: 61 3717-3300
                    </Typography>
                </Box>
            </section>

            <Footer />
        </Fragment>
    )
}