//import React, { StrictMode } from 'react';
//import ReactDOM from 'react-dom/client';

import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


// ReactDOM.render(
//   <App />,
//   document.querySelector('#root')
// );

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(<App />);

