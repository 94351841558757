import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx'

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 388,
        height: 235,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #707070',
        borderRadius: '4px',
        outline: 'transparent',
        boxShadow: '0px 10px 35px -10px rgba(112,112,112,1)',
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        color: '#0D0048',
        fontSize: '20px',
        lineHeight: '1.5',
    },
    text: {
        color: '#424242',
        fontSize: '16px',
    },
    alignButton: {
        position: 'absolute',
        top: '10px',
        right: '17px',
        cursor: 'pointer',
        color: '#7A7A7A',
    },
    alignText: {
        position: 'absolute',
        bottom: '25px',
        right: '42px',
        cursor: 'pointer',
    },
    formatText: {
        color: '#0D0048',
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'uppercase',
        margin: '0',
    }
}));

export function SimpleModal(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const handleCloseModal = () => {
        return false
    }
    const handleClose = () => {
        return false
    }
    const body = (
        <Box style={modalStyle} className={clsx(classes.paper, 'modal')} onClose={(props.handleClose) ? props.handleClose : handleCloseModal}>
            <h2 id="simple-modal-title" className={classes.title}>{props.title}</h2>
            <span id="simple-modal-description" className={classes.text}>
                {props.text}
            </span>
            <CloseIcon className={classes.alignButton} onClick={(props.handleClose) ? props.handleClose : handleCloseModal} />

            <Typography variant="body2" className={classes.alignText} onClick={(props.handleClose) ? props.handleClose : handleCloseModal} >
                <span className={classes.formatText}>OK</span>
            </Typography>
            <SimpleModal />
        </Box>
    );

    return (
        <Modal
            open={(props.handleOpen) ? props.handleOpen : false}
            onClose={(props.handleClose) ? props.handleClose : handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}