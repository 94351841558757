import React, { Fragment } from 'react';
import { Header } from '../../components/Header/app.js'
import { Footer } from '../../components/Footer/app.js'
import { FormDelete } from './form.js'
import { Box, makeStyles, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: 'rgb(1, 4, 17)',
        background: 'linear-gradient(270deg, rgba(1,4,17,1) 5%, rgba(38,43,72,1) 100%)', // eslint-disable-line no-useless-computed-key
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            /*backgroundColor: '#0D0048', background: 'transparent',*/
        }
    },
    container: {
        width: '1196px',
        margin: 'auto',
        padding: '55px 0 55px',
        ['@media (max-width:1212px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 170px)',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 74px)',
            padding: '0 0 55px',
        }
    },
    blcSocial: {
        display: "flex",
        justifyContent: "center",
        margin: '20px 0 0',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 74px)',
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            margin: '40px 0 0',
            height: "130px",
            '& > * + *': {
                margin: theme.spacing(0),
            },
        },
    },
    boxSocial: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoSocial: {
        display: 'block',
        maxWidth: '100%',
        height: '17px',
        marginRight: '10px',
    },
    linkSocialYoutube: {
        background: 'url(./img/icon_youtube.png) no-repeat left 0',
        width: '55px',
        height: '20px',
        padding: '0 0 0 31px',
        color: '#fff',
        '&:hover': {
            background: 'url(./img/icon_youtube_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
    linkSocialInstagram: {
        background: 'url(./img/icon_instagram.png) no-repeat left 0',
        width: '66px',
        height: '20px',
        padding: '0 0 0 28px',
        color: '#fff',
        '&:hover': {
            background: 'url(./img/icon_instagram_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
    linkSocialFacebook: {
        background: 'url(./img/icon_facebook.png) no-repeat left 0',
        width: '64px',
        height: '20px',
        padding: '0 0 0 16px',
        color: '#fff',
        '&:hover': {
            background: 'url(./img/icon_facebook_hover.png) no-repeat left 0',
            color: '#01D1FE',
        },
    },
    boxTitle: {
        margin: '50px 0',
        textAlign: 'center',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            margin: '35px 0 20px',
            paddingTop: '20px'
        }
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '40px 0',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: 'center',
            padding: '20px 0',
        }
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        }
    },
    txtRight: {
        width: '500px',
        marginRight: '50px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            marginRight: '0',
            textAlign: 'center',
        }
    },
    txtLeft: {
        width: '500px',
        marginLeft: '50px',
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            marginLeft: '0',
            textAlign: 'center',
        }
    },
}));

export function ExclusaoDeDados() {
    const classes = useStyles();

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 500);
    
    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box textAlign="center" className={classes.container}>
                    <Box className={classes.boxTitle}>
                        <Typography variant="h1"><strong>Exclusão de dados</strong></Typography>
                        <Typography variant="h3">
                            <p>Para solicitar a exclusão dos seus dados basta preencher o formulário abaixo.</p>                            
                        </Typography>                        
                        <List>
                            <Typography variant="h4">Os dados a serem excluídos são:</Typography>
                            <ListItemText>
                                <Typography variant="h4">&#8226; Telefone(s)</Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="h4">&#8226; E-mail(s)</Typography>
                            </ListItemText>
                        </List>
                        <List>
                            <Typography variant="h4">Serão mantidos os seguintes dados para efeito de auditoria das Bolsas e fiscalização por até 5 anos:</Typography>
                            <ListItemText>
                                <Typography variant="h4">&#8226; CPF(s)</Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="h4">&#8226; Endereço(s)</Typography>
                            </ListItemText>
                        </List>                        
                    </Box>

                    <Box className="container formPadding">
                        <FormDelete idForm="exclusaoDeDados"/><Fragment />
                    </Box>
                    
                    <Box className={classes.boxTitle}>
                        <Typography variant="h1">
                            <strong>Fale conosco</strong>
                        </Typography>
                        <Typography>
                            A Central de Atendimento do Valor PRO está disponível de segunda a sexta-feira pelo telefone 0800-003-1232 e pelo e-mail falecom@valor.com.br
                        </Typography>
                    </Box>
                </Box>
            </section>
            <Footer />
        </Fragment>
    )
}