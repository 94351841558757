import React, { useState, version } from 'react';

// fields
import { ButtonSendRequestCustom } from '../../../../components/Fields/button'
import { Text } from '../../../../components/Fields/text'
import { DateNoRequired } from '../../../../components/Fields/date'

// services
import { validateForm } from '../../../../business/UserService.js'
import { formConfig } from '../../../../business/ConfigService.js'
import { Mask } from '../../../../business/MaskService.js'
import { SV_API_CONTA } from '../../../../business/ExternalUrlService'

// libs
import { Typography, Box, makeStyles, Button, Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles(theme => ({
    middleTitle: {
    	display: 'block',
        color: '#fff',
        fontSize: '19px',
        fontWeight: '400',
        lineHeight: '1.4',
    },
    smallTitle: {
        display: 'block',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '1.2'
    },
    smallLabel: {
        fontSize: '17px',
        letterSpacing: '0.15px',
    },
    titleColor: {
        color: '#0D0048'
    },
    smallAlert: {
        position: 'relative',
        top: '-30px',
        right: '-290px',
        fontSize: '12px!important',
        letterSpacing: '0.15px',
        maxWidth: '100px',        
        color: '#7D7D7D', 
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            right: '-230px',
        },
    },
    none: {
        display: 'none'
    },
    titleSpacing: {
        marginBottom: theme.spacing(3)
    },
    boxOption: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    marginPoliticaPrivacidade: {
        marginTop: '50px'
    },
    marginRadio: {
        marginLeft: '8px'
    },
    esqueceuSenhaLink: {
        width: '194px',
        height: '19px',        
        textAlign: 'center',
        font: 'normal normal 900 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#3D3078!important',
        textTransform: 'uppercase',
        opacity: '1',
        "&:hover": {
            textDecoration: 'underline!important',
        },
        ['@media (max-width:980px)']: {
            width: '90%',
        }
    },
    btnAzulEscuro: {        
        width: '114px',
        height: '43px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',        
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnAzulEscuroEditar: {        
        width: '158px',
        height: '50px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    btnBranco: {
        margin: '15px',
        width: '140px',
        height: '43px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#3D3078!important',
        opacity: '1',
        border: '2px solid #3D3078',
        "&:hover": {
            textDecoration: 'none',
            background: '#FFFFFF',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    checkBox: {
        marginLeft: '6px',
        marginTop: '-11px',
        marginBottom: '19px' //top, right, bottom, left
    },
    imgBtnposicao: {
        height: '28px',
        width: '28px',
        left: '25px',
        position: 'absolute',
        top: '11px',
    },
    imgBtnEditCliente: {
        //height: '28px',
        //width: '28px',
        //position: 'absolute',
        //top: '21px',
        //left: '18px',
        marginTop: '15px',
        textAlign: 'center'
    },
    txtBtnposicao: {
        position: 'absolute',
        top: '16px',
        left: '65px',
        fontWeight: '400',
        lineHeight: '1.4',
        textAlign: 'center',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '1.28px',
        color: '#fff',
        textTransform: 'uppercase',
    },
    checkbox: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #707070',        
        borderRadius: '360px',        
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 20,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: 'translateX(15px)',
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 1,
            color: '#7D7D7D',
            "&.Mui-checked": {
                transform: 'translateX(15px)',
                color: '#3D3078',
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
                },
            },
        },        
        "& .MuiSwitch-thumb": {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 20,            
            borderRadius: 16,
            transition: theme.transitions.create(['width'], { duration: 200, }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 0,            
            backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
            boxSizing: 'border-box',
            
        },
    },
    lineFormat: {
    	border: '1px solid #00000026',
		opacity: '1',
    },
    noEditableLabel: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#7B7B7B',
        width: '180px',
        height: '19px',
        marginBottom: '17px',
    },
    noEditableInfo: {
        font: 'normal normal normal 16px/28px Roboto',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#303030',
        width: '370px',
        height: '19px',
        marginBottom: '17px',
    },
    groupTitle: {
        paddingTop: '30px',
        paddingBottom: '56px',
        width: '580px',
        height: '22px',
        textAlign: 'left',
        font: 'normal normal medium 18px/28px Roboto',
        letterSpacing: '0px',
        color: '#333333',
    },
    executiveTitle: {
        width: '223px',
        height: '29px',
        textAlign: 'left',
        font: 'normal normal bold 24px/29px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    executiveInfo: {
        marginBottom: '10px',
        width: '580px',
        height: '19px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    formTitle: {
        paddingTop: '22px',
        width: '181px',
        height: '28px',
        textAlign: 'left',
        font: 'normal normal bold 23px/28px Roboto',
        letterSpacing: '0.17px',
        color: '#0D0048',
    },
    infoAtendimento: {
        //top: '1531px',
        left: '431px',
        width: '442px!important',
        height: '47px',
        textAlign: 'left',
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0px',
        color: '#303030',
    },
    btnAzulEscuroIconeApenas: {
        width: '50px',
        height: '50px',
        left: '5px',
        background: '#3D3078 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        color: '#fff',
        opacity: '1',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        },
        ['@media (max-width:980px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
}));


export function FormularioAlterarDadosMaster(props){
    
    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));

	/*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: 'Dados atualizados com sucesso.',
        button: validateForm(conf)        
    });
    
    const [selectedDate, setSelectedDate] = useState(null);    

    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {        
            version.textSnack = error;
            setAlertSeverity('error'); /* SNACKBAR */
        }

        setStage(version);
        setAlertMessage(version.textSnack);
        setOpen(true);
    };

    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
            version.loading = true;
            setStage(version)
        e.preventDefault();
        
        /* Usar sessão para forçar parâmetro */
        //submit('PUT', stage, SV_API_CONTA+'/updatePassword', version, callBack);
        alert(`Enviar dados para ${SV_API_CONTA}`)
    };

    const buttonLabel = () => {
        return (
            <Typography style={{ font: 'normal normal bold 16px/19px Roboto' }}>Salvar</Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        classes: classes.btnAzulEscuro
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };

    return (
        <form id={ props.idForm } onSubmit={ onFormSubmit } className="containerForm3" style={{ minHeight: "1720px" }}>
        	<Box className={ classes.titleSpacing }>
        		<Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
        			<Grid item md={7}>
        				<Box textAlign="left" className={ classes.titleSpacing }>
			                <Typography className={ classes.formTitle }>Dados cadastrais</Typography>
			            </Box>
        			</Grid>
                    <Grid item>
                        <Box>
                            <Button className={ classes.btnAzulEscuroIconeApenas } href="#/minhaConta/alterarSenha">
                                <img src="img/icons/ico_password_white.svg" alt="icon_password"/>
                            </Button>
                        </Box>
                    </Grid>
        		</Grid>
                <br/>
                <Box sx={{ height: "650px", width: "100%" }}>
					<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Tipo:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Administrador</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Empresa:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>XPTO</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>CNPJ:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>000.000.000/0000-00</Box>
						</Grid>
                	</Grid>
                	<br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                	<Box className={ classes.groupTitle }>Dados do responsável</Box>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Nome:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Marcos J. Penteado</Box>
						</Grid>
                	</Grid>
                    <br/>
                	<Box textAlign="left">                        
                		<Text blur={ handleBlur } change={ handleChange } stage={ stage.cargo } />
                		<Text blur={ handleBlur } change={ handleChange } stage={ stage.departamento } />
                        <Box id="boxOption" className={ classes.boxOption } >
                            <Box>
                                <DateNoRequired
                                    selected={ selectedDate } 
                                    onChange={ date => setSelectedDate(date) }
                                    id="birthDate"
                                    blur={ handleBlur }
                                    stage={ stage.maioridade }
                                />
                            </Box>
                        </Box>
            		</Box>            		
            		<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>CPF:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>000.000.000-00</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>E-mail:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>marcosjpenteado@email.com</Box>
						</Grid>
                	</Grid>
                    <Box textAlign="left">
                        <br/>
                		<Text blur={ handleBlur } change={ handleChange } stage={ stage.emailRecuperacao } />
                		<Text blur={ handleBlur } change={ handleChange } stage={ stage.telefone } />
                		<Text blur={ handleBlur } change={ handleChange } stage={ stage.celular } />
            		</Box>
            		<hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                	<Box className={ classes.groupTitle }>Endereço</Box>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>CEP:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>00000-000</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Logradouro:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Rua A</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Número:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>007</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Complemento:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Bond, James Bond!</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Bairro:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Zendar</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Cidade:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>Londres</Box>
						</Grid>
                	</Grid>
                	<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item md={4}>
							<Box className={ classes.noEditableLabel }>Estado:</Box>
						</Grid>
						<Grid item md={6}>
							<Box className={ classes.noEditableInfo }>EN</Box>
						</Grid>
                	</Grid>
                	<br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr><br/>
                    <Box>
                        <Box>
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                                <Grid item>
                                    <img src="/img/icons/ico_info.svg" alt="icon_info"/>
                                </Grid>
                                <Grid item md={10} className={ classes.infoAtendimento }>
                                    Para editar outras informações, favor entrar em contato com nosso atendimento.
                                </Grid>
                            </Grid>
                        </Box>
                        <br/>
                        <Box textAlign="right">
                            <Button className={ classes.btnBranco } href="#/minhaConta/meusDados">Cancelar</Button>
                            <ButtonSendRequestCustom stage={ stage.button }>{ button }</ButtonSendRequestCustom>
                        </Box>
                    </Box>                    
                </Box>
			</Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
        </form>
    )

}

export function FormularioAlterarDadosUsuario(props){
    const classes = useStyles();
    const conf = Object.assign({}, formConfig(props.idForm));

    /*SNACKBAR*/
    const [open, setOpen] = React.useState(false);
    const [message, setAlertMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    const [stage, setStage] = React.useState({
        ...conf,
        textSnack: 'Dados atualizados com sucesso.',
        button: validateForm(conf)        
    });

    const [selectedDate, setSelectedDate] = useState(null);

    const callBack = (error) => {
        let version = Object.assign({}, stage)
        
        if (error) {        
            version.textSnack = error;
            setAlertSeverity('error'); /* SNACKBAR */
        }

        setStage(version);
        setAlertMessage(version.textSnack);
        setOpen(true);
    };

    const onFormSubmit = async (e) => {
        let version = Object.assign({}, stage);
            version.loading = true;
            setStage(version)
        e.preventDefault();
        
        //submit('PUT', stage, SV_API_CONTA+'/updatePassword', version, callBack);
    };

    const buttonLabel = () => {
        return (
            <Typography style={{ font: 'normal normal bold 16px/19px Roboto' }}>Salvar</Typography>
        )
    };

    const button = {
        title: buttonLabel(),
        class: 'button',
        classes: classes.btnAzulEscuro
    };

    const handleChange = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        Mask(version[field.id]);
        setStage(version);
    };

    const handleBlur = (field) => {
        let version = Object.assign({}, stage);
        version[field.id] = field;
        version.button = validateForm(version);
        setStage(version);
    };
    
    const handleClose = () => {
        const state = Object.assign({}, stage)
        state.open = false
        setStage(state);

        window.location.reload();
    };

    return (
        <form id={ props.idForm } onSubmit={ onFormSubmit } className="containerForm3" style={{ minHeight: "1303px" }}>
            <Box className={classes.titleSpacing}>
                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item md={7}>
                        <Box textAlign="left" className={ classes.titleSpacing }>
                            <Typography className={ classes.formTitle }>Dados cadastrais</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Button className={ classes.btnAzulEscuroIconeApenas } href="#/minhaConta/alterarSenha">
                                <img src="img/icons/ico_password_white.svg" alt="icon_password"/>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <br/>
                <Box sx={{ height: "650px", width: "100%" }}>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Tipo:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Usuário final</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Nome:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Marcos J. Penteado</Box>
                        </Grid>
                    </Grid>
                    <br/>
                    <Box id="boxOption" className={ classes.boxOption } >
                        <Box>
                            <DateNoRequired
                                selected={ selectedDate } 
                                onChange={ date => setSelectedDate(date) }
                                id="birthDate"
                                blur={ handleBlur }
                                stage={ stage.maioridade }
                            />
                        </Box>
                    </Box>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>CPF:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>000.000.000-00</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>E-mail:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>marcosjpenteado@email.com.br</Box>
                        </Grid>
                    </Grid>
                    <br/>
                    <Box textAlign="left">
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.emailRecuperacao } />
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.telefone } />
                        <Text blur={ handleBlur } change={ handleChange } stage={ stage.celular } />
                    </Box>
                    <hr class="MuiDivider-root" className={ classes.lineFormat }></hr>
                    <Box className={ classes.groupTitle }>Endereço</Box>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>CEP</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>00000-000</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Logradouro:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Rua A</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Número:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>007</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Complemento:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Bond, James Bond!</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Bairro</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Zendar</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Cidade:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>Londres</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={4}>
                            <Box className={ classes.noEditableLabel }>Estado:</Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={ classes.noEditableInfo }>HQ</Box>
                        </Grid>
                    </Grid>
                    <br/><hr class="MuiDivider-root" className={ classes.lineFormat }></hr><br/>
                    <Box>
                        <Box>
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                                <Grid item>
                                    <img src="/img/icons/ico_info.svg" alt="icon_info"/>
                                </Grid>
                                <Grid item md={10} className={ classes.infoAtendimento }>
                                    Para editar outras informações, favor entrar em contato com nosso atendimento.
                                </Grid>
                            </Grid>
                        </Box>
                        <br/>
                        <Box textAlign="right">
                            <Button className={ classes.btnBranco } href="#/minhaConta/meusDados">Cancelar</Button>
                            <ButtonSendRequestCustom stage={ stage.button }>{ button }</ButtonSendRequestCustom>
                        </Box>
                    </Box>                    
                </Box>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={ open } autoHideDuration={ 6000 } onClose={ handleClose } >
                <Alert className={ classes.alertDefault } severity={ severity }>{ message }</Alert>
            </Snackbar>
        </form>

    )
}