import React, { Fragment } from 'react';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '1500',
    },
    shadow: {
        backgroundColor: '#fff',
        position: 'fixed!important',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0.7',
        zIndex: '1800',
    },
    load: {
        position: 'fixed!important',
        top: '50%',
        left: '50%',
        marginTop: '-32px',
        marginLeft: '-32px',
        zIndex: '1900',
    },
    track: {
        color: '#01BEE6',
    },
}));

export function CircularDeterminate(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root} display={props.loading ? 'block' : 'none'} >
            <Box className={classes.shadow}></Box>
            <Box className={classes.load}>
                <CircularProgressForm />
            </Box>
        </Box>
    );
}

export function CircularProgressForm(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        function tick() {
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Fragment>
            <CircularProgress size='5em' variant="determinate" value={progress} className={classes.track} />
        </Fragment>
    );
}