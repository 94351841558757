import React, { Fragment } from 'react';
import { FormHelperText, Checkbox, FormControlLabel, FormGroup, withStyles, Box } from '@material-ui/core';
import { setUnknown } from '../../business/UserService'

const BlueCheckbox = withStyles({
    root: {
        '&$checked': {
            color: '#3D3078',
        },
        transform: 'scale(0.77)',
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

export function CheckField(props) {
    const handleChange = event => {
        const version = Object.assign({}, props.stage)
        let result = setUnknown(version, event.target.checked)
        version['value'] = event.target.checked
        props.stage.value = event.target.checked
        version['error'] = false
        version['helperText'] = ''
        if (result.error) {
            version['error'] = true
            version['helperText'] = result.error
        }
        props.blur(version);
    };

    return (
        <Fragment>
            <FormGroup row >
                <FormControlLabel
                    id={props.stage.id}
                    control={<BlueCheckbox
                        checked={props.stage.value}
                        onChange={handleChange}
                        name={props.stage.id}
                        required={props.stage.required}
                        disabled={props.stage.disabled}
                    />}
                    label={props.stage.label}
                />
            </FormGroup>
            <Box>
                <FormHelperText error={props.stage.error} >{props.stage.helperText}</FormHelperText>
            </Box>
        </Fragment>

    );
}
