import React, { Fragment } from 'react';

import { HeaderMinhaConta } from '../../Menu/app.js'
import { Footer } from '../../../../components/Footer/app.js'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { FormularioAlterarCliente } from './form'

import '../../../../index.css';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#070026',
        opacity: '1',
        color: '#fff',
        paddingTop: '80px',
        paddingBottom: '100px',        
        //display: 'none'
    },
    container: {
        padding: '55px 0',
        //height: '318px',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 24px)',
            margin: 'auto',
            padding: '20px 0',
        },
        ['@media (max-width:320px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        },
    },
    textBox: {
        textAlign: 'center',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 50px)',
            margin: 'auto',
        },
    },
    infoTitulo: {
        top: '132px',
        //left: '85px',
        width: '180px',
        height: '23px',
        textAlign: 'left',
        font: 'normal normal bold 18px/46px RedHatText',
        fontFamily: 'RedHatText',
        letterSpacing: '1.8px!important',
        color: '#fff',
        textTransform: 'uppercase',
        //https://stackoverflow.com/questions/41676054/how-to-add-fonts-to-create-react-app-based-projects
    },
    infoSecao: {
        top: '160px',
        //left: '85px',
        width: '195px',
        height: '43px',
        textAlign: 'left',
        font: 'normal normal bold 32px/46px RedHatText!important',
        fontFamily: 'RedHatText!important',
        letterSpacing: '0px',
        color: '#01D1FE',        
    },
    infoLogado: {
        top: '181px',
        //left: '1043px',
        //width: '238px',
        height: '22px',
        textAlign: 'right',
        font: 'normal normal normal 18px/17px Roboto',
        letterSpacing: '0px',
        color: '#FFFFFFDE',
    },
    boxMenu: {
        position: 'absolute',
        left: '0px', //'670px',
        top: '28px',
        textAlign: 'left'
        //border: '3px solid #73AD21'
    },
    boxLogado: {
        position: 'absolute',
        right: '0px', //'670px',
        top: '28px',
        textAlign: 'right'
        //border: '3px solid #73AD21'
    },
    

}));

export function AlterarCliente() {

    const classes = useStyles();
    
    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight + 229);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 200);

    return (
        <Fragment>
            <HeaderMinhaConta />
            <Box style={{ backgroundColor: "#070026", height: "55px", position: "relative" }}>&nbsp;</Box>
            <center>
                <Box style={{ backgroundColor: "#070026", height: "66px", alignItems: 'flex-start' }}>
                    <Box style={{ width: "1200px", position: "relative" }}>
                        <Box className={classes.boxMenu}>
                            <Typography className={ classes.infoTitulo }>
                                <strong>Administração</strong>
                            </Typography>
                            <Typography className={ classes.infoSecao }><strong>Usuários</strong></Typography>
                        </Box>
                        <Box className={ classes.boxLogado }>
                            <br/>
                            <Typography className={classes.infoLogado}>
                                Olá { sessionStorage.getItem('username') }    
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </center>            
            <section id="section" className={classes.root}>
                <Box className="container formPadding">
                    <FormularioAlterarCliente idForm="alterarCliente"/>
                    <Fragment />
                </Box>
            </section>
            <Footer />
        </Fragment>
    )
}