import axios from "axios";

import { EXT_API_DOWNLOAD } from '../business/ExternalUrlService';

//axios.get(DOWNLOAD_API)
const api = axios.create({
    baseURL: EXT_API_DOWNLOAD,
  });

export default api;
