import React, { Fragment } from 'react';

// components
import { Header } from '../../components/Header/app.js'
import { Footer } from '../../components/Footer/app.js'
import { FormUser } from './form' /* form */
//import { RadioButtonsGroup } from '../../components/Fields/radioBox'
//import { optionPessoa } from '../../business/OptionsService' /* service */
import { Box, Typography, makeStyles } from '@material-ui/core' /* libs */


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#0d0048',
        color: '#fff',
    },
    container: {
        padding: '55px 0',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 24px)',
            margin: 'auto',
            padding: '20px 0',
        },
        ['@media (max-width:320px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        },
    },
    textBox: {
        textAlign: 'center',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: 'calc(100% - 50px)',
            margin: 'auto',
        },
    },
    option: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    pessoaLabel: {
        marginTop: '50px!important',
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '35px!important',
        },
    }
}));


/* ContratacaoFisica */
export function VendaPessoaFisicaMobile(props) {
    const classes = useStyles();
    const parameters = props.location.search.split('?');
    let plano = ((parameters[2] === "1") ? "plano mensal" : "plano anual");

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);
            //console.log(blocoSection);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 500);

    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box textAlign="center" className={classes.container}>
                    <Box className={classes.textBox}>
                        <Typography variant="h1">Contratar o pacote <strong>Valor PRO Mobile</strong></Typography>
                        <Typography variant="body1">Preencha o formulário abaixo para contratar o {plano}</Typography>
                        <Typography variant="body1" className={classes.pessoaLabel}>&nbsp;</Typography>
                    </Box>
                    
                    <Box className="container formPadding">
                        <FormUser idForm="pessoaFisicaContratacao" idReferencia="4" idServico={parameters[1]} assinatura={parameters[2]}/><Fragment />
                    </Box>
                    
                </Box>
            </section >
            <Footer />
        </Fragment >
    )
}

export function VendaPessoaFisicaBronze(props) {
    const classes = useStyles();
    const parameters = props.location.search.split('?');
    let plano = ((parameters[2] === "1") ? "plano mensal" : "plano anual");

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);
            //console.log(blocoSection);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 500);

    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box textAlign="center" className={classes.container}>
                    <Box className={classes.textBox}>
                        <Typography variant="h1">Contratar o pacote <strong>Valor PRO Bronze</strong></Typography>
                        <Typography variant="body1">Preencha o formulário abaixo para contratar o {plano}</Typography>
                        <Typography variant="body1" className={classes.pessoaLabel}>&nbsp;</Typography>
                    </Box>
                    
                    <Box className="container formPadding">
                        <FormUser idForm="pessoaFisicaContratacao" idReferencia="3" idServico={parameters[1]} assinatura={parameters[2]}/><Fragment />
                    </Box>
                    
                </Box>
            </section >
            <Footer />
        </Fragment >
    )
}

/*
export function ContratacaoJuridica() {
    const classes = useStyles();
    const [formType, setFormType] = React.useState('');

    const handleBlur = (field) => {
        setFormType(field.value);
    };

    const setup = {
        placement: 'top',
        color: 'secondary',
        size: 'medium'
    };

    const handleScroll = () => {
        let sectionEl = document.getElementById("section");
        if (sectionEl) {
            let pageInnerHeight = window.innerHeight; //altura da tela
            let pageWindowsHeight = document.body.clientHeight; //altura do scroll
            let blocoSection = (pageInnerHeight - 229);
            //console.log(blocoSection);

            if (pageWindowsHeight <= (pageInnerHeight - 100)) {
                sectionEl.setAttribute('style', 'min-height:' + blocoSection + 'px')
            }
        }
    };

    const bind = () => {
        document.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
    };

    setTimeout(function () {
        handleScroll();
        bind();
    }, 500);

    return (
        <Fragment>
            <Header />
            <section id="section" className={classes.root}>
                <Box textAlign="center" className={classes.container}>
                    <Box className={classes.textBox}>
                        <Typography variant="h1">Solicite uma contratação</Typography>
                        <Typography variant="body1">Preencha o formulário abaixo para solicitar sua contratação.</Typography>
                        <Typography variant="body1" className={classes.pessoaLabel}>&nbsp;</Typography>
                    </Box>

                    <Box className="container formPadding">
                        <FormUser idForm="pessoaJuridicaContratacao" /><Fragment />
                    </Box>
                    
                </Box>
            </section >
            <Footer />
        </Fragment >
    )
}
*/