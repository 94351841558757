import React from 'react'
import { Typography, makeStyles } from '@material-ui/core' /*, Button */
import { HashLink as Link } from 'react-router-hash-link'
//import SubMenuDesk from './subMenuDesk.js';

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: 'none!important',
        '& > * + *': {
            marginLeft: theme.spacing(4),
        },
    },
    nav: {
        alignItems: 'flex-start',
        ['@media (max-width:880px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        },
    },
    menuLinks: {
        textDecoration: 'none'
    },
    btnDemonstracao: {
        backgroundColor: '#01BEE6',
        color: '#fff',
        fontSize: '0.75rem',
        padding: '11.5px 20px',
        borderRadius: '4px',
        "&:hover": {
            backgroundColor: '#01D1FE',
            color: '#fff',
            textDecoration: 'none',
        }
    },
}));

export function MenuMaster() {
    const classes = useStyles();
    
    return (
        <nav className={ classes.nav }>
            <Typography variant="h5" className={ classes.root } style={{ color: "#fff", textTransform: "uppercase" }}>
                <Link to="/minhaConta/clientes" className={ classes.menuLinks }>Usuários</Link>
                <Link to="/minhaConta/meusDados" className={ classes.menuLinks }>Dados</Link>
                <Link to="/minhaConta/servicos" className={ classes.menuLinks }>Contrato</Link>
                <Link to="/minhaConta/downloads" className={ classes.menuLinks }>Downloads</Link>
                <Link to="/minhaConta/faleConosco" className={ classes.menuLinks }>Fale Conosco</Link>
                <Link to="/minhaConta/sair" className={ classes.menuLinks }>Sair</Link>
            </Typography>
        </nav>
    );
}

export function MenuUsuario() {
    const classes = useStyles();

    return (
        <nav className={ classes.nav }>
            <Typography variant="h5" className={ classes.root } style={{ color: "#fff", textTransform: "uppercase" }}>
                <Link to="/minhaConta/meusDados" className={ classes.menuLinks }>Dados</Link>
                <Link to="/minhaConta/servicos" className={ classes.menuLinks }>Contrato</Link>
                <Link to="/minhaConta/downloads" className={ classes.menuLinks }>Downloads</Link>
                <Link to="/minhaConta/faleConosco" className={ classes.menuLinks }>Fale Conosco</Link>
                <Link to="/minhaConta/sair" className={ classes.menuLinks }>Sair</Link>
            </Typography>
        </nav>
    );
}